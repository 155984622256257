import { CheckoutAddressForm as SourceCheckoutAddressForm } from "SourceComponent/CheckoutAddressForm/CheckoutAddressForm.component";

import "./CheckoutAddressForm.override.style";

export class CheckoutAddressForm extends SourceCheckoutAddressForm {
  componentDidMount() {
    const {
      address: { countryId, regionId, region, city, postcode },
      defaultCountry,
      onShippingEstimationFieldsChange,
      //   checkChangeField,
    } = this.props;

    onShippingEstimationFieldsChange({
      country_id: countryId || defaultCountry,
      region_id: regionId !== "" ? regionId : null,
      region,
      city,
      postcode,
    });
  }

  changeCheck = (event, data) => {
    this.props.checkChangeField && this.props.checkChangeField(event, data);
  };

  get fieldMap() {
    const fieldMap = super.fieldMap;
    const addressGroup =
      fieldMap?.length > 0 &&
      fieldMap?.find(({ name }) => name === "addressGroup");
    if (addressGroup) {
      addressGroup.events = {
        // Updates shipping methods on address blurt
        onBlur: this.onAddressChange,
        // Updates shipping methods on start
        onLoad: this.onAddressChange,
      };
    }

    fieldMap.map((data) => {
      if (data?.type === "text") {
        data.events = {
          // Updates shipping methods on address blurt
          onBlur: this.changeCheck,
        };
      }
      if (data?.name === "streetGroup") {
        data.fields[0].events = {
          onBlur: this.changeCheck,
        };
      }
      if (data?.name === "addressGroup") {
        data.fields.map((data) => {
          data.events = {
            onBlur: this.changeCheck,
          };
        });
      }
    });

    //   fieldMap.splice(0, 2);

    return fieldMap;
  }
  // TODO implement logic
  render() {
    const { isPickInStoreMethodSelected = false } = this.props;

    if (isPickInStoreMethodSelected) {
      return <div block="CheckoutAddressForm">{this.renderFormBody()}</div>;
    }

    return (
      <div block="CheckoutAddressForm">
        <h3>{__("Add Address")}</h3>
        {this.renderFormBody()}
      </div>
    );
  }
}

export default CheckoutAddressForm;
