import {
    Image as SourceImage,
} from 'SourceComponent/Image/Image.component';
import lmcNoImage from "./lmc_placeholder_640.jpg"

import './Image.override.style';

import {
    IMAGE_LOADED, IMAGE_LOADING, IMAGE_NOT_FOUND, IMAGE_NOT_SPECIFIED
} from './Image.config';

export class Image extends SourceImage {
    // TODO implement logic

    renderImageNotFound() {
        const {
            alt,
            src,
            style,
            title,
            className
        } = this.props;

        if (navigator.onLine) {
            return (
                <span block="Image" elem="Content">
                    <img
                        block={ className }
                        src={ lmcNoImage }
                        title={ 'placeholder' }
                        loading="lazy"
                    />
                </span>
            );
        }

        return <span block="Image" elem="Content" mods={ { isOffline: true } } />;
    }

    renderStyledImage() {
        const {
            alt,
            src,
            style,
            title,
            device,
            height,
            width,
            className
        } = this.props;
        const { imageStatus } = this.state;
        console.log("imagerendering renderStyleImage",src)
        return (
            <img
              block="Image"
              elem="Image"
              src={ src || '' }
              alt={ alt }
              mods={ { isLoading: imageStatus === IMAGE_LOADING } }
              title={ title }
              onLoad={ this.onLoad }
              onError={ this.onError }
              loading={(window.location.pathname == '/' || title == "removeLazyMobileImage") ? "" : "lazy"}
              height={title == "Logo" ? (device?.isMobile ? "40" : "78") : height || ""}
              width={title == "Logo" ? (device?.isMobile ? "49" : "278") : width || ""}
            />
        );
    }

    renderPlainImage() {
        const {
            alt,
            src,
            style,
            title,
            className,
            height,
            width,
            device
        } = this.props;
        console.log("imagerendering renderStyleImage",src)

        return (this.props.height || this.props.width) ? (
            <img
              block={ className }
              src={ src || '' }
              alt={ alt }
              title={ title }
              onLoad={ this.onLoad }
              onError={ this.onError }
              loading={className.includes("remove-lazy") ? "" : "lazy"}
              height={height || ""}
              width={width || ""}
              
            />
        ) : (
            <img
              block={ className }
              src={ src || '' }
              alt={ alt }
              title={ title }
              onLoad={ this.onLoad }
              onError={ this.onError }
              loading={className.includes("remove-lazy") ? "" : "lazy"}
            />
        );
    }
};

export default Image;
