import { connect } from 'react-redux';

import {
    mapStateToProps as sourceMapStateToProps,
    mapDispatchToProps as sourceMapDispatchToProps,
    CheckoutBillingContainer as SourceCheckoutBillingContainer,
} from 'SourceComponent/CheckoutBilling/CheckoutBilling.container';
import transformToNameValuePair from 'Util/Form/Transform';
import axios from 'axios';
import Cookies from 'js-cookie';
import { STORE_IN_PICK_UP_METHOD_CODE } from 'Component/StoreInPickUp/StoreInPickUp.config';
import { KLARNA, PURCHASE_ORDER } from 'Component/CheckoutPayments/CheckoutPayments.config';
import PropTypes from 'prop-types';
import { TotalsType } from 'Type/MiniCart';
import { showPopup } from 'Store/Popup/Popup.action';
import { Addresstype, CustomerType } from 'Type/Account';
import { PaymentMethodsType } from 'Type/Checkout';
import CheckoutBilling from './CheckoutBilling.component';
import { formatPrice } from 'Util/Price';
import Skeleton from 'react-loading-skeleton';
import { BILLING_STEP } from 'Route/Checkout/Checkout.config';
import {
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from 'react-accessible-accordion';

export const mapStateToProps = state => ({
    ...sourceMapStateToProps(state),   
    unbxd_config_sitekey: state.ConfigReducer.unbxd_config_sitekey,
    unbxd_config_apikey: state.ConfigReducer.unbxd_config_apikey,
    unbxd_config_secretkey: state.ConfigReducer.unbxd_config_secretkey,
    base_url: state.ConfigReducer.base_url,
});

export const mapDispatchToProps = dispatch => ({
    ...sourceMapDispatchToProps(dispatch),
    // TODO extend mapDispatchToProps
});

export class CheckoutBillingContainer extends SourceCheckoutBillingContainer {
    static propTypes = {
        showErrorNotification: PropTypes.func.isRequired,
        paymentMethods: PaymentMethodsType.isRequired,
        savePaymentInformation: PropTypes.func.isRequired,
        showPopup: PropTypes.func.isRequired,
        shippingAddress: Addresstype.isRequired,
        customer: CustomerType.isRequired,
        totals: TotalsType.isRequired,
        addressLinesQty: PropTypes.number.isRequired,
        termsAndConditions: PropTypes.arrayOf(PropTypes.shape({
            checkbox_text: PropTypes.string,
            content: PropTypes.string,
            name: PropTypes.string
        })).isRequired,
        selectedShippingMethod: PropTypes.string.isRequired,
        cartTotalSubPrice: PropTypes.number,
        setDetailsStep: PropTypes.func.isRequired,
        setLoading: PropTypes.func.isRequired,
        termsAreEnabled: PropTypes.bool,
        isLoading: PropTypes.bool,
    };
    static defaultProps = {
        termsAreEnabled: false,
        cartTotalSubPrice: null
    };
    containerFunctions = {
        onBillingSuccess: this.onBillingSuccess.bind(this),
        onBillingError: this.onBillingError.bind(this),
        onAddressSelect: this.onAddressSelect.bind(this),
        onSameAsShippingChange: this.onSameAsShippingChange.bind(this),
        onPaymentMethodSelect: this.onPaymentMethodSelect.bind(this),
        showPopup: this.showPopup.bind(this)
    };
    __construct(props) {
        super.__construct(props);

        const { paymentMethods, customer } = props;
        const [method] = paymentMethods;
        const { code: paymentMethod } = method || {};

        this.state = {
            isSameAsShipping: this.isSameShippingAddress(customer),
            selectedCustomerAddressId: 0,
            prevPaymentMethods: paymentMethods,
            paymentMethod,
            isAccordionOpen: false
        };
    }
    containerProps() {
        const {
            cartTotalSubPrice,
            paymentMethods,
            selectedShippingMethod,
            setDetailsStep,
            setLoading,
            shippingAddress,
            termsAndConditions,
            termsAreEnabled,
            totals,
            isLoading
        } = this.props;
        const { isSameAsShipping, paymentMethod } = this.state;

        return {
            cartTotalSubPrice,
            paymentMethods,
            isSameAsShipping,
            selectedShippingMethod,
            setDetailsStep,
            setLoading,
            shippingAddress,
            termsAndConditions,
            termsAreEnabled,
            totals,
            isLoading,
            paymentMethod
        };
    }
    // TODO implement logic
    isSameShippingAddress({ default_billing, default_shipping }) {
        const { totals: { is_virtual }, selectedShippingMethod } = this.props;

        if (is_virtual) {
            return false;
        }

        return default_billing === default_shipping;
    }

    onBillingSuccess(form, fields, asyncData) {
        const { savePaymentInformation, unbxd_config_sitekey, base_url, totals : {items} } = this.props;
        const { isSameAsShipping } = this.state;

        const extractedFields = transformToNameValuePair(fields);
        const address = this._getAddress(extractedFields);
        const paymentMethod = this._getPaymentData(extractedFields, asyncData);

        var getVisiterId = Cookies.get("unbxd.visitId");
        var getVisitType = Cookies.get("unbxd.visit");
        var getUnbxdUserId = Cookies.get("unbxd.userId");


        let axiosAllArr = [];
        var Unbxd = window.Unbxd || '';
        items.forEach(element => {

            var data = `data={"pid": "${element.product.id}", "price": "${element.price}","qty" : "${element.qty}", "url":"${base_url}${element.product.url}","referrer":"","visit_type":"${getVisitType}"}`;

            var config = {
                url: `https://tracker.unbxdapi.com/v2/1p.jpg?${data}&UnbxdKey="${unbxd_config_sitekey}"&action=order&uid=${getUnbxdUserId}&t=1572240947154|0.04593068792473942a`
            };

            setTimeout(() => {
                Unbxd.track("order",{"pid":`${element.product.id}`,"qty":`${element.qty}`,"price":`${element.price}`})
            }, 1000);
            // axiosAllArr.push(axios.get(config.url))
        });

        // axios.all(axiosAllArr).then(axios.spread((...responses) => {
        // console.log("responses", responses)
        // // use/access the results 
        // })).catch(errors => {
        // // react on errors.
        // })

        savePaymentInformation({
            billing_address: address,
            paymentMethod,
            same_as_shipping: isSameAsShipping
        });
    }
    _getPaymentData(fields, asyncData) {
        const { paymentMethod: code } = this.state;

        switch (code) {
        case KLARNA:
            const [{ authorization_token }] = asyncData;

            return {
                code,
                additional_data: {
                    authorization_token
                }
            };

        case PURCHASE_ORDER:
            const { purchaseOrderNumber } = fields;

            return {
                code,
                purchase_order_number: purchaseOrderNumber
            };
        case 'stripe_payments':
            const [{ token, handleAuthorization }] = asyncData;
            if (token === null) {
                return false;
            }

            return {
                code,
                additional_data: {
                    cc_stripejs_token: token,
                    cc_save: false
                },
                handleAuthorization
            };

        default:
            return { code };
        }
    }
    renderHeading() {
            
            
        const { totals: { grand_total, quote_currency_code } } = this.props;

        const orderTotal = formatPrice(grand_total, quote_currency_code);

        return (
            <h2 block="Checkout" elem="Heading">
                { __('Payment Method') }
                <div className="rgtprice">
                <h4> <span>{ __('Payable amount') }:</span> { orderTotal == "$NaN" ? <div className='mySkeleton'><Skeleton /></div> : orderTotal}</h4>
                <p>({ __('Inc. GST') })</p>
                </div>
            </h2>
        );
    }

    componentDidUpdate(prevProps) {
        const { shippingAddress } = this.props;
        const {isAccordionOpen} = this.state
    
        if (
          shippingAddress &&
          Object.entries(shippingAddress).length > 0 &&
          (!prevProps.shippingAddress || Object.entries(prevProps.shippingAddress).length === 0)
        ) {
          // Perform your desired function here
        //   console.log('Shipping address is true for the first time!');
          this.setState({ isAccordionOpen: true })
        }
    }

    render() {
        const { shippingAddress , onBillingSuccess, onBillingError } = this.props;
        const {isAccordionOpen} = this.state

        // var isExpanded = false;

        // if(shippingAddress && Object.entries(shippingAddress).length > 0)
        // {
        //     isExpanded = true;
        // }   
        return (
            <>
                {/* <AccordionItem  key={ BILLING_STEP } uuid={ BILLING_STEP } dangerouslySetExpanded={ isExpanded } > */}
                <AccordionItem  key={ BILLING_STEP } uuid={ BILLING_STEP } dangerouslySetExpanded={ isAccordionOpen }>
                    <span onClick={() => {
                        if(shippingAddress && Object.entries(shippingAddress).length > 0)
                        {   
                            this.setState({ isAccordionOpen: !isAccordionOpen })
                        }  
                    }}>
                        <AccordionItemHeading>
                            <AccordionItemButton>
                                { this.renderHeading() }
                            </AccordionItemButton>
                        </AccordionItemHeading>
                    </span>
                    {/* //dhruvin checkoutpage - paymentmethod accordian +\- is not clickable */}
                    <AccordionItemPanel>
                        <CheckoutBilling
                        { ...this.containerProps() }
                        { ...this.containerFunctions }
                        />
                    </AccordionItemPanel>
                </AccordionItem>   
            </>
        );
    }

};

export default connect(mapStateToProps, mapDispatchToProps)(CheckoutBillingContainer);
