import {
    CartPage as SourceCartPage,
    Checkout as SourceCheckout,
    CmsPage as SourceCmsPage,
    CookiePopup as SourceCookiePopup,
    DemoNotice as SourceDemoNotice,
    Header as SourceHeader,
    HomePage as SourceHomePage,
    MyAccount as SourceMyAccount,
    PasswordChangePage as SourcePasswordChangePage,
    SearchPage as SourceSearchPage,
    ConfirmAccountPage as SourceConfirmAccountPage,
    MenuPage as SourceMenuPage,
    Footer as SourceFooter,
    NavigationTabs as SourceNavigationTabs,
    NewVersionPopup as SourceNewVersionPopup,
    NotificationList as SourceNotificationList,
    WishlistShared as SourceWishlistShared,
    OfflineNotice as SourceOfflineNotice,
    ContactPage as SourceContactPage,
    ProductComparePage as SourceProductComparePage,
    CreateAccountPage as SourceCreateAccountPage,
    LoginAccountPage as SourceLoginAccountPage,
    ForgotPasswordPage as SourceForgotPasswordPage,
    SomethingWentWrong as SourceSomethingWentWrong,
    StyleGuidePage as SourceStyleGuidePage,
    Breadcrumbs as SourceBreadcrumbs,
    withStoreRegex as sourceWithStoreRegex,
    Router as SourceRouter,
} from 'SourceComponent/Router/Router.component';
import { lazy } from 'react';
import { Route } from 'react-router-dom';
import {
    AFTER_ITEMS_TYPE,
    BEFORE_ITEMS_TYPE,
    SWITCH_ITEMS_TYPE,
} from './Router.config';
import './Router.override.style';
export const Tracking = lazy(() =>
  import(
    /* webpackMode: "lazy", webpackChunkName: "Tracking" */ "Route/Tracking"
  )
);
//TODO: implement CartPage
export const CartPage = SourceCartPage;

//TODO: implement Checkout
export const Checkout = SourceCheckout;

//TODO: implement CmsPage
export const CmsPage = SourceCmsPage;

//TODO: implement CookiePopup
export const CookiePopup = SourceCookiePopup;

//TODO: implement DemoNotice
export const DemoNotice = SourceDemoNotice;

//TODO: implement Header
export const Header = SourceHeader;

//TODO: implement HomePage
export const HomePage = SourceHomePage;

//TODO: implement MyAccount
export const MyAccount = SourceMyAccount;

//TODO: implement PasswordChangePage
export const PasswordChangePage = SourcePasswordChangePage;

//TODO: implement SearchPage
export const SearchPage = SourceSearchPage;

//TODO: implement ConfirmAccountPage
export const ConfirmAccountPage = SourceConfirmAccountPage;

//TODO: implement MenuPage
export const MenuPage = SourceMenuPage;

//TODO: implement Footer
export const Footer = SourceFooter;

//TODO: implement NavigationTabs
export const NavigationTabs = SourceNavigationTabs;

//TODO: implement NewVersionPopup
export const NewVersionPopup = SourceNewVersionPopup;

//TODO: implement NotificationList
export const NotificationList = SourceNotificationList;

//TODO: implement WishlistShared
export const WishlistShared = SourceWishlistShared;

//TODO: implement OfflineNotice
export const OfflineNotice = SourceOfflineNotice;

//TODO: implement ContactPage
export const ContactPage = SourceContactPage;

//TODO: implement ProductComparePage
export const ProductComparePage = SourceProductComparePage;

//TODO: implement CreateAccountPage
export const CreateAccountPage = SourceCreateAccountPage;

//TODO: implement LoginAccountPage
export const LoginAccountPage = SourceLoginAccountPage;

//TODO: implement ForgotPasswordPage
export const ForgotPasswordPage = SourceForgotPasswordPage;

//TODO: implement SomethingWentWrong
export const SomethingWentWrong = SourceSomethingWentWrong;

//TODO: implement StyleGuidePage
export const StyleGuidePage = SourceStyleGuidePage;

//TODO: implement Breadcrumbs
export const Breadcrumbs = SourceBreadcrumbs;

//TODO: implement withStoreRegex
export const withStoreRegex = sourceWithStoreRegex;

export class Router extends SourceRouter {

    constructor(props) {
        super(props);

        this[SWITCH_ITEMS_TYPE].push( {
            component: <Route path={ withStoreRegex('/tracking') } exact  render={ (props) => <Tracking { ...props } /> } />,
            position: 100,
            name: 'Tracking'
        },
        {
            component: <Route path={ withStoreRegex('/tracking/:code') } exact render={ (props) => <Tracking { ...props } /> } />,
            position: 101,
            name: 'Tracking'
        },
        // {
        //     component: <Route path={ withStoreRegex('/stripe/webhooks') } exact render={ (props) => <>Your webhooks endpoint is accessible from your location.</> } />,
        //     position: 102,
        //     name: 'Tracking'
        // }
        );
    }

    renderDefaultRouterContent() {
        if (location.pathname.match('/styleguide')) {
            return this.renderMainItems();
        }
        // if (location.pathname.match('/stripe/webhooks')) {
        //     return this.renderMainItems();
        // }

        return (
            <>
                { this.renderSectionOfType(BEFORE_ITEMS_TYPE) }
                { this.renderMainItems() }
                { this.renderSectionOfType(AFTER_ITEMS_TYPE) }
            </>
        );
    }
};

export default Router;
