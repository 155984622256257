import {
    CheckoutPayments as SourceCheckoutPayments,
} from 'SourceComponent/CheckoutPayments/CheckoutPayments.component';

import './CheckoutPayments.override.style';

import CmsBlock from 'Component/CmsBlock';
import CheckoutPayment from 'Component/CheckoutPayment';
import { KLARNA, PURCHASE_ORDER } from './CheckoutPayments.config';
import Stripe from '@scandipwa/stripe-payments/src/component/Stripe';
import PayPal from '../../../packages/paypal/src/component/PayPal';


export class CheckoutPayments extends SourceCheckoutPayments {
    // TODO implement logic
    paymentRenderMap = {
        [KLARNA]: this.renderKlarnaPayment.bind(this),
        [PURCHASE_ORDER]: this.renderPurchaseOrderPayment.bind(this),
        ['stripe_payments']: this.renderStripePayment.bind(this),
        ['paypal_express']: this.renderPayPal.bind(this),
    };
    renderStripePayment() {
        console.log("renderStripePayment",this.props);

        const {
            billingAddress,
            setStripeRef,
            paymentTotals = {},
            totals: cartTotals,
            onPaymentMethod
        } = this.props;

        const totals = Object.keys(paymentTotals).length ? paymentTotals : cartTotals;

        return (
            <Stripe
              billingAddress={ billingAddress }
              setStripeRef={ setStripeRef }
              paymentTotals={ totals }
              onPaymentMethod={ onPaymentMethod }
            />
        );
    }
    renderPayPal(){
        const {
            selectedPaymentCode,
            setLoading,
            setDetailsStep,
            widgetlocation
        } = this.props;
    
        return (
            <PayPal
              setLoading={ setLoading }
              widgetLocation={ widgetlocation }
              setDetailsStep={ setDetailsStep }
              selectedPaymentCode={ selectedPaymentCode }
            />
        );
    };
    
    renderBankTransfer() {
        
        return <CmsBlock identifier='bank_transfer_instruction' key="bank-transfer-instruction" type="bank-transfer-instruction" />;
    }

    renderSelectedPayment() {
        const { selectedPaymentCode } = this.props;

        // if(selectedPaymentCode=='banktransfer')
        // {
        //     return this.renderBankTransfer();
        // }
         if(selectedPaymentCode=='banktransfer')
        {
            return null;
        }
        // if(selectedPaymentCode=='walletorder')
        // {
        //     return null;
        // }  
        if(selectedPaymentCode=='stripe_payments')
        {
            return null
        }

        const render = this.paymentRenderMap[selectedPaymentCode];

        if (!render) {
            return null;
        }
        return render();
    }

    renderPayments() {
        const { paymentMethods } = this.props;
        const newArrayObject = {
            "code": "stripe_payments",
            "title": "Wallet Order",
            "location": null,
            "isWallet": true,
        };
        const isFirefoxOrEdge = window.navigator.userAgent.toLowerCase().indexOf('firefox') > -1 || window.navigator.userAgent.toLowerCase().indexOf('edge') > -1
        const updatedArray = (isFirefoxOrEdge || !this.props?.device?.isMobile) ? paymentMethods : [...paymentMethods, newArrayObject];

        return updatedArray.map(this.renderPayment);
    }
    renderPayment = (method) => {
        const {
            selectPaymentMethod,
            selectedPaymentCode,
            isWalletSelected
        } = this.props;
        const { code } = method;
        const { isWallet } = method;
        const isSelected = selectedPaymentCode === code;
        if(code == "paypal_express" && this.props?.selectedShippingMethod == "pickup"){
            return null
        }
        if(isWallet){
            return (
                <CheckoutPayment
                  key={ code }
                  isSelected={ isSelected && isWalletSelected }
                  method={ method }
                  onClick={ selectPaymentMethod }
                  render = {this.paymentRenderMap["stripe_payments"]}
                  isWallet = {true}
                  device = {this.props?.device}
                />
            );
        }
        return (
            <CheckoutPayment
              key={ code }
              isSelected={ isSelected && !isWalletSelected }
              method={ method }
              onClick={ selectPaymentMethod }
              render = {this.paymentRenderMap[selectedPaymentCode]}
              isWallet = {isWallet || false}
              device = {this.props?.device}
            />
        );
    };
    
    renderContent() {
        const { hasError } = this.state;

        if (hasError) {
            return (
                <p>{ __('The error occurred during initializing payment methods. Please try again later!') }</p>
            );
        }

        return (
            <>
                <ul block="CheckoutPayments" elem="Methods">
                    { this.renderPayments() }
                </ul>
                { this.renderSelectedPayment() }
            </>
        );
    }
};

export default CheckoutPayments;
