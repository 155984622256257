import {
    NONE_SORT_OPTION_VALUE as SOURCE_NONE_SORT_OPTION_VALUE,
    BEST_MATCH_SORT_OPTION_VALUE as SOURCE_BEST_MATCH_SORT_OPTION_VALUE,
    NONE_SORT_OPTION as SOURCE_NONE_SORT_OPTION,
} from 'SourceRoute/SearchPage/SearchPage.config';

//TODO: implement NONE_SORT_OPTION_VALUE
export const NONE_SORT_OPTION_VALUE = SOURCE_NONE_SORT_OPTION_VALUE;

//TODO: implement BEST_MATCH_SORT_OPTION_VALUE
export const BEST_MATCH_SORT_OPTION_VALUE = SOURCE_BEST_MATCH_SORT_OPTION_VALUE;

//TODO: implement NONE_SORT_OPTION
export const NONE_SORT_OPTION = SOURCE_NONE_SORT_OPTION;
