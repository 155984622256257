import {
    KLARNA as SOURCE_KLARNA,
    CHECK_MONEY as SOURCE_CHECK_MONEY,
    PAYPAL_EXPRESS as SOURCE_PAYPAL_EXPRESS,
    PAYPAL_EXPRESS_CREDIT as SOURCE_PAYPAL_EXPRESS_CREDIT,
    PURCHASE_ORDER as SOURCE_PURCHASE_ORDER,
} from 'SourceComponent/CheckoutPayments/CheckoutPayments.config';

//TODO: implement KLARNA
export const KLARNA = SOURCE_KLARNA;

//TODO: implement CHECK_MONEY
export const CHECK_MONEY = SOURCE_CHECK_MONEY;

//TODO: implement PAYPAL_EXPRESS
export const PAYPAL_EXPRESS = SOURCE_PAYPAL_EXPRESS;

//TODO: implement PAYPAL_EXPRESS_CREDIT
export const PAYPAL_EXPRESS_CREDIT = SOURCE_PAYPAL_EXPRESS_CREDIT;

//TODO: implement PURCHASE_ORDER
export const PURCHASE_ORDER = SOURCE_PURCHASE_ORDER;
