/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import { GET_PAYMENTMETHODDATA } from "./PaymentMethodDatas.action";

/** @namespace Store/ProductCompare/Reducer/getInitialState */
export const getInitialState = () => ({
  extraFeeGet: {},
});

/** @namespace Store/ProductCompare/Reducer/ProductCompareReducer */
export const PaymentMethodDatasreducer = (state = getInitialState(), action) => {
  const { type } = action;

  switch (type) {
    case GET_PAYMENTMETHODDATA:
      return {
        ...state,
        extraFeeGet: action.payload,
      };
    default:
      return state;
  }
};

export default PaymentMethodDatasreducer;
