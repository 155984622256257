import { connect } from 'react-redux';
import PropTypes from "prop-types";
export const PaymentMethodDatasDispatcher = import("Store/PaymentMethodDatas/PaymentMethodDatas.dispatcher");
import {
    mapDispatchToProps as sourceMapDispatchToProps,
    mapStateToProps as sourceMapStateToProps,
    CheckoutPaymentsContainer as SourceCheckoutPaymentsContainer,
} from 'SourceComponent/CheckoutPayments/CheckoutPayments.container';
import { KlarnaContainer } from 'Component/Klarna/Klarna.container';
import { KLARNA } from './CheckoutPayments.config';
import { STRIPE } from 'src/@scandipwa/stripe-payments/plugin/CheckoutPayments.plugin';
import { BILLING_STEP } from 'Route/Checkout/Checkout.config';
import { SHIPPING_STEP } from 'Route/Checkout/Checkout.config';
import { CustomerType } from 'Type/Account';
import { isSignedIn } from 'Util/Auth';
export const MyAccountDispatcher = import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    'Store/MyAccount/MyAccount.dispatcher'
);

export const mapDispatchToProps = dispatch => ({
    ...sourceMapDispatchToProps(dispatch),
    fetchPaymentfees: (id, customerId) =>
    PaymentMethodDatasDispatcher.then(({ default: dispatcher }) =>
      dispatcher.getPaymentDatas(dispatch, id , customerId)
    ),
    requestCustomerData: () => MyAccountDispatcher.then(
        ({ default: dispatcher }) => dispatcher.requestCustomerData(dispatch)
    ),
    // TODO extend mapDispatchToProps
});

export const mapStateToProps = state => {
    return({
    ...sourceMapStateToProps(state),
    customer: state.MyAccountReducer.customer,
    // TODO extend mapStateToProps
    device: state.ConfigReducer.device
})};

export class CheckoutPaymentsContainer extends SourceCheckoutPaymentsContainer {

    static propTypes = {
        ...this.propTypes,
        fetchPaymentfees: PropTypes.func,
        requestCustomerData: PropTypes.func.isRequired,
        customer: CustomerType.isRequired,
      };
    
    async fetchPaymentfees(code, customerEmail) {
        const { fetchPaymentfees } = this.props;
        return await fetchPaymentfees(code , customerEmail);
    }

    // TODO implement logic
    
    containerFunctions = {
        selectPaymentMethod: this.selectPaymentMethod.bind(this),
        setAdditionalCharges: this.setAdditionalCharges.bind(this),
        setStripeRef: this.setStripeRef.bind(this),
        onPaymentMethod: this.onPaymentMethod.bind(this)
    };

    dataMap = {
        [KLARNA]: this.getKlarnaData.bind(this),
        [STRIPE]: this.getStripeData.bind(this)
    };
    __construct(props) {
        super.__construct(props);

        const { paymentMethods } = props;
        const [{ code } = {}] = paymentMethods;
        this.state = {
            selectedPaymentCode: "",
            isWalletSelected: false,
        };
    }
    containerProps() {
        const {
            billingAddress,
            paymentMethods,
            setOrderButtonEnableStatus,
            showError,
            totals,
            customer,
            device,
            selectedShippingMethod
        } = this.props;

        const { selectedPaymentCode, isWalletSelected } = this.state;

        return {
            billingAddress,
            paymentMethods,
            selectedPaymentCode,
            isWalletSelected,
            setOrderButtonEnableStatus,
            showError,
            totals,
            customer,
            device,
            selectedShippingMethod
        };
    }

    componentDidMount() {
        if (window.formPortalCollector) {
            window.formPortalCollector.subscribe(BILLING_STEP, this.collectAdditionalData, 'CheckoutPaymentsContainer');
        }
        
        document.querySelector(".CheckoutPayment-Button_isSelected")?.click(); 
 
        const { selectedPaymentCode, setOrderButtonVisibility } = this.props;
    
        if (selectedPaymentCode == 'paypal_express') {
            setOrderButtonVisibility(false);
        }

    }
    componentDidUpdate(prevProps) {
        // const [prevProps] = args;
        const { selectedPaymentCode, setOrderButtonVisibility } = this.props;
        const { selectedPaymentCode: prevSelectedPaymentCode } = prevProps;

        if (selectedPaymentCode !== prevSelectedPaymentCode) {
            if (selectedPaymentCode == 'paypal_express') {
                setOrderButtonVisibility(false);
            }

            if (prevSelectedPaymentCode == 'paypal_express') {
                setOrderButtonVisibility(true);
            }
        }

        // callback.apply(instance, args);

    }

    componentWillUnmount() {
        if (window.formPortalCollector) {
            window.formPortalCollector.unsubscribe(BILLING_STEP, 'CheckoutPaymentsContainer');
        }
    }

    getStripeData() {
        /* Check if one-click-payment*/
        const { paymentMethod } = this.state;
        if (!paymentMethod) {
            return { asyncData: this.stripeRef.submit() };
        }
        
        const token = `${paymentMethod.id}:${paymentMethod.card.brand}:${paymentMethod.card.last4}`;

        return { asyncData: Promise.resolve({ token }) };
    }

      /** Instance context */
    setStripeRef(ref) {
        this.stripeRef = ref;
    }

    onPaymentMethod(paymentMethod) {
        this.setState(
            () => ({ paymentMethod }),
            () => {
                document.getElementById(BILLING_STEP).dispatchEvent(new Event('submit'));
            }
        );
    }
    /* call payment fee api*/
    setAdditionalCharges(code){
        const {
            updateExtraFee,
            requestCustomerData,
            customer
        } = this.props;
        
        const {
                customer: {
                    id: customerId,
                    email: customerEmail
                } = {}
            } = this.props;
        if (isSignedIn()) {
            requestCustomerData();
        }
        return this.fetchPaymentfees(code, customerEmail);
    }

    collectAdditionalData = () => {
        
        const { selectedPaymentCode } = this.state;
        
        const additionalDataGetter = this.dataMap[selectedPaymentCode];
        
        if (!additionalDataGetter) {
            return {};
        }
        return additionalDataGetter();
    };

    selectPaymentMethod({ code, isWallet }) {
        const {
            onPaymentMethodSelect,
            setOrderButtonEnableStatus
        } = this.props;
        if(code == "banktransfer"){
            this?.props?.setLoading(true)
        }
        this.setState({
            isWalletSelected: isWallet ? true : false
        });
        this.setState({
            selectedPaymentCode: code
        });
        onPaymentMethodSelect(code);
        setOrderButtonEnableStatus(true);
        this.setAdditionalCharges(code);  /* payment on click call payment fee api */
        setTimeout(() => {
            document.getElementById(SHIPPING_STEP).dispatchEvent(new Event('submit', { cancelable: true }));
        }, 2000);
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(CheckoutPaymentsContainer);
