import {
    FieldSelect as SourceFieldSelect,
} from 'SourceComponent/PureForm/FieldSelect/FieldSelect.component';

import './FieldSelect.override.style';

export class FieldSelect extends SourceFieldSelect {
    // TODO implement logic
};

export default FieldSelect;
