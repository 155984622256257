import {
    IMAGE_LOADING as SOURCE_IMAGE_LOADING,
    IMAGE_LOADED as SOURCE_IMAGE_LOADED,
    IMAGE_NOT_FOUND as SOURCE_IMAGE_NOT_FOUND,
    IMAGE_NOT_SPECIFIED as SOURCE_IMAGE_NOT_SPECIFIED,
    IMAGE_HUNDRED_PERCENT as SOURCE_IMAGE_HUNDRED_PERCENT,
} from 'SourceComponent/Image/Image.config';

//TODO: implement IMAGE_LOADING
export const IMAGE_LOADING = SOURCE_IMAGE_LOADING;

//TODO: implement IMAGE_LOADED
export const IMAGE_LOADED = SOURCE_IMAGE_LOADED;

//TODO: implement IMAGE_NOT_FOUND
export const IMAGE_NOT_FOUND = SOURCE_IMAGE_NOT_FOUND;

//TODO: implement IMAGE_NOT_SPECIFIED
export const IMAGE_NOT_SPECIFIED = SOURCE_IMAGE_NOT_SPECIFIED;

//TODO: implement IMAGE_HUNDRED_PERCENT
export const IMAGE_HUNDRED_PERCENT = SOURCE_IMAGE_HUNDRED_PERCENT;
