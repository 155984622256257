import {
    UPDATE_PRODUCT_LIST_INFO as SOURCE_UPDATE_PRODUCT_LIST_INFO,
    UPDATE_INFO_LOAD_STATUS as SOURCE_UPDATE_INFO_LOAD_STATUS,
    updateProductListInfo as sourceUpdateProductListInfo,
    updateInfoLoadStatus as sourceUpdateInfoLoadStatus,
} from 'SourceStore/ProductListInfo/ProductListInfo.action';

//TODO: implement UPDATE_PRODUCT_LIST_INFO
export const UPDATE_PRODUCT_LIST_INFO = SOURCE_UPDATE_PRODUCT_LIST_INFO;

//TODO: implement UPDATE_INFO_LOAD_STATUS
export const UPDATE_INFO_LOAD_STATUS = SOURCE_UPDATE_INFO_LOAD_STATUS;

//TODO: implement updateProductListInfo
export const updateProductListInfo = sourceUpdateProductListInfo;

//TODO: implement updateInfoLoadStatus
export const updateInfoLoadStatus = sourceUpdateInfoLoadStatus;
