/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import { GET_STOCK_LIST } from "./StockGet.action";

/** @namespace Store/ProductCompare/Reducer/getInitialState */
export const getInitialState = () => ({
  getStock: {},
});

/** @namespace Store/ProductCompare/Reducer/ProductCompareReducer */
export const StockGetReducer = (state = getInitialState(), action) => {
  const { type } = action;

  switch (type) {
    case GET_STOCK_LIST:
      return {
        ...state,
        getStock: action.payload,
      };
    default:
      return state;
  }
};

export default StockGetReducer;
