
import PropTypes from 'prop-types';

import {
    CheckoutBilling as SourceCheckoutBilling,
} from 'SourceComponent/CheckoutBilling/CheckoutBilling.component';

import './CheckoutBilling.override.style';
 

import Field from 'Component/PureForm/Field';
import FIELD_TYPE from 'Component/PureForm/Field/Field.config';
import Form from 'Component/PureForm/Form';
import { STORE_IN_PICK_UP_METHOD_CODE } from 'Component/StoreInPickUp/StoreInPickUp.config';
import { BILLING_STEP } from 'Route/Checkout/Checkout.config';
import { formatPrice } from 'Util/Price';

import Skeleton from 'react-loading-skeleton';
import Loader from 'Component/Loader';
import { Addresstype } from 'Type/Account';
import { PaymentMethodsType } from 'Type/Checkout';
import { TotalsType } from 'Type/MiniCart';
import CheckoutPayments from 'Component/CheckoutPayments';


import { PaymentRequestButtonElement } from '@stripe/react-stripe-js';

import { PaymentRequest } from '@scandipwa/stripe-payments/src/type/Stripe';


import {
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from 'react-accessible-accordion';

export class CheckoutBilling extends SourceCheckoutBilling {
    state = {
        isOrderButtonVisible: true,
        isOrderButtonEnabled: true,
        isTermsAndConditionsAccepted: false,
        isStripeCardDetailsComplete: false,
    };
    static propTypes = {
        paymentRequest: PaymentRequest.isRequired,
        setLoading: PropTypes.func.isRequired,
        setDetailsStep: PropTypes.func.isRequired,
        isSameAsShipping: PropTypes.bool.isRequired,
        termsAreEnabled: PropTypes.bool.isRequired,
        onSameAsShippingChange: PropTypes.func.isRequired,
        onPaymentMethodSelect: PropTypes.func.isRequired,
        onBillingSuccess: PropTypes.func.isRequired,
        onBillingError: PropTypes.func.isRequired,
        onAddressSelect: PropTypes.func.isRequired,
        showPopup: PropTypes.func.isRequired,
        paymentMethods: PaymentMethodsType.isRequired,
        totals: TotalsType.isRequired,
        cartTotalSubPrice: PropTypes.number.isRequired,
        shippingAddress: Addresstype.isRequired,
        termsAndConditions: PropTypes.arrayOf(PropTypes.shape({
            checkbox_text: PropTypes.string
        })).isRequired,
        selectedShippingMethod: PropTypes.string.isRequired,
        isLoading: PropTypes.bool
    };
    componentWillUnmount() {
        this.setupMutationObserver(true);
        this.setState({ isStripeCardDetailsComplete: false });
      }
    
    setupMutationObserver(shouldDisconnect) {
        if (document.querySelector(".StripeElement") && !shouldDisconnect) {
          var mutationObserver = new MutationObserver(mutationsList => {
            for (const mutation of mutationsList) {
              if (mutation.type === 'attributes' && mutation.attributeName === 'class') {
                if (document.querySelector(".StripeElement").classList.contains('StripeElement--complete')) {
                  this.setState({ isStripeCardDetailsComplete: true });
                }else{
                    this.setState({ isStripeCardDetailsComplete: false });
                }
              }
            }
          });
          mutationObserver?.observe(document.querySelector(".StripeElement"), { attributes: true });
        }else if(shouldDisconnect){
            mutationObserver?.disconnect();
            this.setState({ isStripeCardDetailsComplete: false });
        }else{
            this.setState({ isStripeCardDetailsComplete: false });
        }
    }
    renderHeading() {
        return null;
    }


    renderAddresses() {
        return (
            <>
                { this.renderSameAsShippingCheckbox() }
                { this.renderAddressBook() }
            </>
        );
    }


    renderSameAsShippingCheckbox() {
        const {
            isSameAsShipping,
            onSameAsShippingChange,
            totals: { is_virtual },
            selectedShippingMethod
        } = this.props;

        if (is_virtual) {
            return null;
        }

        return (
            <Field
              type={ FIELD_TYPE.checkbox }
              attr={ {
                  id: 'sameAsShippingAddress',
                  name: 'sameAsShippingAddress',
                  value: 'sameAsShippingAddress',
                  checked: isSameAsShipping && selectedShippingMethod !== STORE_IN_PICK_UP_METHOD_CODE
              } }
              events={ {
                  onChange: onSameAsShippingChange
              } }
              mix={ { block: 'CheckoutBilling', elem: 'Checkbox' } }
              label={ __('My billing and shipping are the same') }
              onChange={ onSameAsShippingChange }
              isDisabled=''
            />
        );
    }
     
   
    renderActions() {
        const {
            isOrderButtonVisible,
            isOrderButtonEnabled,
            isTermsAndConditionsAccepted
        } = this.state;

        const { termsAreEnabled } = this.props;
        const { paymentMethod, paymentRequest } = this.props;

        if (!isOrderButtonVisible) {
            return null;
        }

        // if terms and conditions are enabled, validate for acceptance
        const isDisabled = termsAreEnabled
            ? !isOrderButtonEnabled || !isTermsAndConditionsAccepted
            : !isOrderButtonEnabled;

        if(paymentMethod == "walletorder"){
            return null
        }
        if(paymentMethod == "stripe_payments"){
            this.setupMutationObserver(false);
        }else{
            this.setState({ isStripeCardDetailsComplete: false });
        }
        // console.log("this.state.isStripeCardDetailsComplete", this.state.isStripeCardDetailsComplete);
        if(paymentMethod == "stripe_payments" && !this.state.isStripeCardDetailsComplete){
            return null
        }
        if(this.props?.isLoading){
            return null
        }

        return (
            <div block="Checkout" elem="StickyButtonWrapper">
                { this.renderOrderTotal() }
                <button
                  type="submit"
                  block="Button"
                  disabled={ isDisabled }
                  mix={ { block: 'CheckoutBilling', elem: 'Button' } }
                >
                    { __('Place Order') }
                </button>
            </div>
        );
    }
    
    renderPayments() {
        const {
            paymentMethods,
            onPaymentMethodSelect,
            setLoading,
            setDetailsStep,
            shippingAddress,
            isLoading
        } = this.props;

        if (!paymentMethods.length) {
            return null;
        }

        return (
            <>
                {isLoading &&  <div block="Loader" style={{position: "fixed", zIndex: "99999999999"}}>
                <div block="Loader" elem="Scale">
                    <div style={{
                        justifyContent: "center",
                        display: "flex",
                        marginBottom: "10px",
                    }}>
                        <div className="bt-spinner"></div>
                    </div>
                    <h4>Please Wait..</h4>
                </div>
            </div>}
                <CheckoutPayments
                  setLoading={ setLoading }
                  setDetailsStep={ setDetailsStep }
                  paymentMethods={ paymentMethods }
                  onPaymentMethodSelect={ onPaymentMethodSelect }
                  setOrderButtonVisibility={ this.setOrderButtonVisibility }
                  billingAddress={ shippingAddress }
                  setOrderButtonEnableStatus={ this.setOrderButtonEnableStatus }
                  selectedShippingMethod={this.props?.selectedShippingMethod}
                />
            </>
        );
    }

    render() {
        const { shippingAddress , onBillingSuccess, onBillingError } = this.props;
        var isExpanded = false;
        if(shippingAddress && Object.entries(shippingAddress).length > 0)
        {
            isExpanded = true;
        }
        return (
                <Form
                  attr={ {
                      id: BILLING_STEP
                  } }  
                  mix={ { block: 'CheckoutBilling' } }
                  id={ BILLING_STEP }
                  onError={ onBillingError }
                  onSubmit={ onBillingSuccess }
                >
                    { this.renderAddresses() }
                    { this.renderPayments() }
                    { this.renderTermsAndConditions() }
                    { this.renderActions() }
                    { this.renderPopup() }
                </Form>
        );   
    }
};

export default CheckoutBilling;
