import { connect } from "react-redux";

import {
  mapStateToProps as sourceMapStateToProps,
  mapDispatchToProps as sourceMapDispatchToProps,
  CheckoutDeliveryOptionsContainer as SourceCheckoutDeliveryOptionsContainer,
} from "SourceComponent/CheckoutDeliveryOptions/CheckoutDeliveryOptions.container";

export const mapStateToProps = (state) => ({
  ...sourceMapStateToProps(state),
  // TODO extend mapStateToProps
  currency: state.ConfigReducer.currencyData.current_currency_code,
  isCustomFeeActivated: state.ConfigReducer.customfee_customfee_status,
  customFeeAmount: state.ConfigReducer.customfee_customfee_customfee_amount,
  customFeeName: state.ConfigReducer.customfee_customfee_name,
  customFeePriceType: state.ConfigReducer.customfee_customfee_pricetype,
  totals: state.CartReducer.cartTotals,
});

export const ExtraFeeDispatcher = import(
  /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
  "store/Checkout/Checkout.dispatcher"
);

export const mapDispatchToProps = (dispatch) => ({
  ...sourceMapDispatchToProps(dispatch),
  // TODO extend mapDispatchToProps
  isShowFee: (isShowFee) =>
    ExtraFeeDispatcher.then(({ default: dispatcher }) =>
      dispatcher.updateisShowFee(isShowFee, dispatch)
    ),
});

export class CheckoutDeliveryOptionsContainer extends SourceCheckoutDeliveryOptionsContainer {
  // TODO implement logic

  state = {
    isShippingMethodPreSelected: true,
    selectedInsurance: 0,
  };

  containerFunctions = {
    selectShippingMethod: this.selectShippingMethod.bind(this),
    onOptionClick: this.onOptionClick.bind(this),
  };

  // onOptionClick(e) {
  //   const { isShowFee, formSubmitFun } = this.props;
  //   this.setState({ selectedInsurance: e.target.value });
  //   isShowFee(e.target.value);
  //   setTimeout(() => {
  //     formSubmitFun("");
  //   }, 1000);
  // }

  onOptionClick(value) {
    const { isShowFee, formSubmitFun } = this.props;
    this.setState({ selectedInsurance: value });
    isShowFee(value);
    setTimeout(() => {
      formSubmitFun("");
    }, 1000);
  }

  selectShippingMethod(shippingMethod) {
    const { onShippingMethodSelect, formSubmitFun } = this.props;
    const { isShippingMethodPreSelected } = this.state;

    if (isShippingMethodPreSelected) {
      this.setState({ isShippingMethodPreSelected: false });
    }

    onShippingMethodSelect(shippingMethod);
    setTimeout(() => {
      formSubmitFun(shippingMethod);
    }, 1000);
  }

  containerProps() {
    const {
      estimateAddress,
      onShippingMethodSelect,
      onStoreSelect,
      shippingMethods,
      handleSelectDeliveryMethod,
      selectedShippingMethod,
      currency,
      isCustomFeeActivated,
      customFeeAmount,
      customFeeName,
      customFeePriceType,
      totals,
      isShowFee,
    } = this.props;
    const { isShippingMethodPreSelected, selectedInsurance } = this.state;

    return {
      estimateAddress,
      onShippingMethodSelect,
      onStoreSelect,
      selectedShippingMethod,
      shippingMethods,
      handleSelectDeliveryMethod,
      isShippingMethodPreSelected,
      currency,
      isCustomFeeActivated,
      customFeeAmount,
      customFeeName,
      customFeePriceType,
      totals,
      selectedInsurance,
      isShowFee,
    };
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CheckoutDeliveryOptionsContainer);
