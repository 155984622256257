import {
    IN_STOCK as SOURCE_IN_STOCK,
    OUT_OF_STOCK as SOURCE_OUT_OF_STOCK,
} from 'SourceComponent/Product/Stock.config';

//TODO: implement IN_STOCK
export const IN_STOCK = SOURCE_IN_STOCK;

//TODO: implement OUT_OF_STOCK
export const OUT_OF_STOCK = SOURCE_OUT_OF_STOCK;
