/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package @scandipwa/paypal
 */

import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';

import CheckoutAddressForm from 'Component/CheckoutAddressForm';
import CheckoutAddressTable from 'Component/CheckoutAddressTable';
import Link from 'Component/Link/Link.component';
import { BILLING_STEP } from 'Route/Checkout/Checkout.config';
import { MY_ACCOUNT_URL } from 'Route/MyAccount/MyAccount.config';
import { ADDRESS_BOOK, CustomerType } from 'Type/Account';

import { PayPalStateType } from '../../type/PayPal.type';

/** @namespace Paypal/Component/InstantPayPalAddress/Component */
export class InstantPayPalAddressComponent extends PureComponent {
     static propTypes = {
         containerFunctions: PropTypes.shape({
             onCheckoutAddressTableClick: PropTypes.func.isRequired,
             onShippingEstimationFieldsChange: PropTypes.func.isRequired,
             expandCustomAddress: PropTypes.func.isRequired
         }).isRequired,
         containerProps: PropTypes.shape({
             customer: CustomerType.isRequired,
             payPalState: PayPalStateType.isRequired,
             setPayPalState: PropTypes.func.isRequired
         }).isRequired
     };

     renderAddress() {
         const {
             containerProps: {
                 payPalState: {
                     isCustomAddressExpanded,
                     shipping_address
                 } = {},
                 customer: {
                     addresses: customerAddresses
                 } = {}
             } = {}
         } = this.props;

         const addresses = [
             { ...shipping_address, id: -1 },
             ...((customerAddresses && customerAddresses.length) ? customerAddresses : [])
         ];

         if (addresses.length && Object.keys(shipping_address).length !== 0) {
             return (
                 <>
                     { this.renderOptionalCustomAddress() }
                     { !isCustomAddressExpanded && this.renderTitle() }
                     { !isCustomAddressExpanded && this.renderAddressList(addresses) }
                 </>
             );
         }

         

         return null;
     }

     renderAddressTable = (address) => {
         const {
             containerFunctions: {
                 onCheckoutAddressTableClick
             } = {},
             containerProps: {
                 payPalState: {
                     selectedAddressId
                 } = {}
             } = {}
         } = this.props;
         const { id } = address;
         const title = (!id || id === -1) ? __('PayPal account address') : __('Address #%s', id);

         return (
             <CheckoutAddressTable
               onClick={ onCheckoutAddressTableClick }
               isSelected={ selectedAddressId === id }
               title={ title }
               address={ address }
               key={ id }
             />
         );
     };

     renderAddressList(addresses = []) {
         if (!addresses || !addresses.length) {
             return this.renderNoAddresses();
         }

         return addresses.map(this.renderAddressTable);
     }

     renderNoAddresses() {
         return (
             <div>
                 <p>{ __('You have no configured addresses.') }</p>
                 <p>
                     <Link to={ `${ MY_ACCOUNT_URL }/${ ADDRESS_BOOK }` }>
                         { __('Go to Address Book to configure them!') }
                     </Link>
                 </p>
             </div>
         );
     }

     renderOptionalCustomAddress() {
         const {
             containerProps: {
                 payPalState: {
                     isCustomAddressExpanded
                 } = {}
             } = {},
             containerFunctions: {
                 expandCustomAddress
             }
         } = this.props;
         const addressButtonText = isCustomAddressExpanded ? __('Use paypal address') : __('Use custom address');

         return (
             <div
               block="CheckoutAddressBook"
               elem="CustomAddressWrapper"
             >
                 <button
                   block="CheckoutAddressBook"
                   elem="Button"
                   mods={ { isCustomAddressExpanded } }
                   mix={ { block: 'Button', mods: { isHollow: true } } }
                   type="button"
                   onClick={ expandCustomAddress }
                 >
                     { addressButtonText }
                 </button>
                 { isCustomAddressExpanded && this.renderCustomAddress() }
             </div>
         );
     }

     renderCustomAddress() {
         const {
             containerProps: {
                 payPalState: {
                     billing_address
                 } = {}
             } = {}
         } = this.props;
         const {
             containerFunctions: {
                 onShippingEstimationFieldsChange
             } = {}
         } = this.props;

         return (
             <CheckoutAddressForm
               onShippingEstimationFieldsChange={ onShippingEstimationFieldsChange }
               address={ billing_address }
               id={ BILLING_STEP }
             />
         );
     }

     renderTitle() {
         return (
             <h2
               block="Checkout"
               elem="Heading"
             >
                 { __('Select a Shipping Address') }
             </h2>
         );
     }

     render() {
         return this.renderAddress();
     }
}

export default InstantPayPalAddressComponent;
