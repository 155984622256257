import {
    STORE_IN_PICK_UP_METHOD_CODE as SOURCE_STORE_IN_PICK_UP_METHOD_CODE,
    STORE_IN_PICK_UP_ATTRIBUTE_CODE as SOURCE_STORE_IN_PICK_UP_ATTRIBUTE_CODE,
} from 'SourceComponent/StoreInPickUp/StoreInPickUp.config';

//TODO: implement STORE_IN_PICK_UP_METHOD_CODE
export const STORE_IN_PICK_UP_METHOD_CODE = SOURCE_STORE_IN_PICK_UP_METHOD_CODE;

//TODO: implement STORE_IN_PICK_UP_ATTRIBUTE_CODE
export const STORE_IN_PICK_UP_ATTRIBUTE_CODE = SOURCE_STORE_IN_PICK_UP_ATTRIBUTE_CODE;
