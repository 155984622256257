/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/paypal
 * @link https://github.com/scandipwa/base-theme
 */

import React, { PureComponent } from 'react';
import { connect } from 'react-redux';

import { PaymentMethodsType } from 'Type/Checkout';

import { WidgetLocationType } from '../../type/PayPal.type';
import InstantPaymentComponent from './InstantPayment.component';

/** @namespace Paypal/Component/InstantPayment/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    payment_methods: state.PaymentMethodsReducer.methods
});

/** @namespace Paypal/Component/InstantPayment/Container/mapDispatchToProps */
export const mapDispatchToProps = () => ({});

/** @namespace Paypal/Component/InstantPayment/Container */
export class InstantPaymentContainer extends PureComponent {
    static propTypes = {
        payment_methods: PaymentMethodsType.isRequired,
        widgetLocation: WidgetLocationType.isRequired
    };

    render() {
        const {
            payment_methods,
            widgetLocation
        } = this.props;

        if (!payment_methods) {
            return null;
        }

        return (
            <InstantPaymentComponent
              payment_methods={ payment_methods }
              widgetlocation={ widgetLocation }
            />
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(InstantPaymentContainer);
