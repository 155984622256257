import {
    Loader as SourceLoader,
} from 'SourceComponent/Loader/Loader.component';

import './Loader.override.style';

export class Loader extends SourceLoader {

    renderMain() {
        return (
                <div>
                    <div class="bt-spinner"></div>
                </div>
        );
    }

    render() {
        const { isLoading } = this.props;

        if (!isLoading) {
            return null;
        }

        return (
            <div block="Loader">
                <div block="Loader" elem="Scale">
                    { this.renderMain() }
                </div>
            </div>
        );
    }
};

export default Loader;
