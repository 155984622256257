import {
    MY_ACCOUNT_URL as SOURCE_MY_ACCOUNT_URL,
    ACCOUNT_LOGIN_URL as SOURCE_ACCOUNT_LOGIN_URL,
    ACCOUNT_URL as SOURCE_ACCOUNT_URL,
} from 'SourceRoute/MyAccount/MyAccount.config';

//TODO: implement MY_ACCOUNT_URL
export const MY_ACCOUNT_URL = SOURCE_MY_ACCOUNT_URL;

//TODO: implement ACCOUNT_LOGIN_URL
export const ACCOUNT_LOGIN_URL = SOURCE_ACCOUNT_LOGIN_URL;

//TODO: implement ACCOUNT_URL
export const ACCOUNT_URL = SOURCE_ACCOUNT_URL;
