import { connect } from 'react-redux';
import PropTypes from 'prop-types';


import {
    mapStateToProps as sourceMapStateToProps,
    mapDispatchToProps as sourceMapDispatchToProps,
    CheckoutDeliveryOptionContainer as SourceCheckoutDeliveryOptionContainer,
} from 'SourceComponent/CheckoutDeliveryOption/CheckoutDeliveryOption.container';

export const mapStateToProps = state => {
    console.log("state", state)
   return ({
    ...sourceMapStateToProps(state),
    // shippingInitialMethod: state.CartReducer.cartTotals.shipping_method
    shippingInitialMethod: state.CheckoutReducer.shippingFields.shippingMethod

    // TODO extend mapStateToProps
})};

export const mapDispatchToProps = dispatch => ({
    ...sourceMapDispatchToProps(dispatch),
    // TODO extend mapDispatchToProps
});

export class CheckoutDeliveryOptionContainer extends SourceCheckoutDeliveryOptionContainer {
    // TODO implement logic

    static propTypes = {
       ...this.propTypes,
       shippingInitialMethod: PropTypes.string
    };

    containerProps() {
        const {
            isSelected,
            getCartShippingItemPrice,
            getCartShippingItemSubPrice,
            option = {},
            totals: {
                quote_currency_code
            },
            shippingInitialMethod
        } = this.props;

        return {
            isSelected,
            option,
            optionPrice: getCartShippingItemPrice(option),
            optionSubPrice: getCartShippingItemSubPrice(option),
            currency: quote_currency_code,
            shippingInitialMethod
        };
    }


    onOptionClick() {
        const { onClick, option = {} } = this.props;


        if (!option.available) {
            return;
        }

        onClick(option);
    }

};

export default connect(mapStateToProps, mapDispatchToProps)(CheckoutDeliveryOptionContainer);
