import {
    ImageContainer as SourceImageContainer,
} from 'SourceComponent/Image/Image.container';

import { IMAGE_HUNDRED_PERCENT } from 'Component/Image/Image.config';

export const mapStateToProps = state => {
    return({
    // TODO extend mapStateToProps
    device: state.ConfigReducer.device
})};

export class ImageContainer extends SourceImageContainer {
    // TODO implement logic
    containerProps() {
        const {
            isPlaceholder,
            src,
            title,
            alt,
            ratio,
            mix,
            imageRef,
            isPlain,
            showIsLoading,
            height,
            width,
            device
        } = this.props;

        return {
            style: this._getStyle(),
            wrapperSize: this._getWrapperSize(),
            isPlaceholder,
            src,
            title,
            alt,
            className: this._getCorrectClassNames(),
            ratio,
            mix,
            imageRef,
            isPlain,
            showIsLoading,
            isCached: this._isCached(),
            height,
            width,
            device
        };
    }
    _getCorrectClassNames() {
        const { width, height, className } = this.props;
        height && console.log("milan image this.props 2222 ==>", this.props)

        const trueMap = [
            this._parseSize(height) === IMAGE_HUNDRED_PERCENT,
            this._parseSize(width) === IMAGE_HUNDRED_PERCENT
        ];
        const classMap = [
            
        ]; 

        const classes = classMap.filter((_, index) => trueMap[index]);

        return [className, ...classes].join(' ');
    }
};

export default ImageContainer;
