import {
    TYPE_PRODUCT as SOURCE_TYPE_PRODUCT,
    TYPE_CMS_PAGE as SOURCE_TYPE_CMS_PAGE,
    TYPE_CATEGORY as SOURCE_TYPE_CATEGORY,
    TYPE_NOTFOUND as SOURCE_TYPE_NOTFOUND,
} from 'SourceRoute/UrlRewrites/UrlRewrites.config';

//TODO: implement TYPE_PRODUCT
export const TYPE_PRODUCT = SOURCE_TYPE_PRODUCT;

//TODO: implement TYPE_CMS_PAGE
export const TYPE_CMS_PAGE = SOURCE_TYPE_CMS_PAGE;

//TODO: implement TYPE_CATEGORY
export const TYPE_CATEGORY = SOURCE_TYPE_CATEGORY;

//TODO: implement TYPE_NOTFOUND
export const TYPE_NOTFOUND = SOURCE_TYPE_NOTFOUND;
