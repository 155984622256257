/* eslint-disable spaced-comment */
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import PropTypes from "prop-types";
import { createRef, PureComponent } from "react";

import { connect } from "react-redux";

import { FIELD_TYPE } from "Component/PureForm/Field/Field.config";
import { MixType } from "Type/Common";
import { EventsType, ValidationRuleType } from "Type/Field";
import { validate } from "Util/Validator";

import Field from "./Field.component";

import transformCountriesToOptions from "Util/Store/Transform";

export const mapStateToProps = (state) => ({
  countries: transformCountriesToOptions(state.ConfigReducer.countries || []),
  defaultCountry: state.ConfigReducer.default_country,
});

export const mapDispatchToProps = (dispatch) => ({});

/**
 * Field
 * @class FieldContainer
 * @namespace Component/PureForm/Field/Container
 */
export class FieldContainer extends PureComponent {
  static propTypes = {
    // Field attributes
    type: PropTypes.oneOf(Object.values(FIELD_TYPE)),
    attr: PropTypes.object,
    events: EventsType,
    isDisabled: PropTypes.bool,
    mix: MixType,
    options: PropTypes.array,

    // Validation
    validationRule: ValidationRuleType,
    validateOn: PropTypes.array,
    showErrorAsLabel: PropTypes.bool,

    // Labels
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    subLabel: PropTypes.string,
    addRequiredTag: PropTypes.bool,

    // Added Extra By Jayeshkumar Lakum
    extraLabel: PropTypes.string,
    className: PropTypes.string,
    step: PropTypes.number,
  };

  static defaultProps = {
    type: FIELD_TYPE.text,
    attr: {},
    events: {},
    mix: {},
    validationRule: {},
    validateOn: [],
    options: [],
    showErrorAsLabel: true,
    isDisabled: false,
    addRequiredTag: false,
    label: "",
    subLabel: "",
    extraLabel: "",
    className: "",
    step: 1,
  };

  state = {
    validationResponse: null,
    isLoading: false,
    value: undefined,
    suggestions: [],
    selectedSuggestion: {},
  };

  containerFunctions = {
    validate: this.validate.bind(this),
    onChange: this.onChange.bind(this),
    onSuggestionsFetchRequested: this.onSuggestionsFetchRequested.bind(this),
    onSuggestionsClearRequested: this.onSuggestionsClearRequested.bind(this),
    onAddressSuggestionsFetchRequested: this.onAddressSuggestionsFetchRequested.bind(this),
    renderSuggestion: this.renderSuggestion.bind(this),
    getSuggestionValue: this.getSuggestionValue.bind(this),
    onSuggestionSelected: this.onSuggestionSelected.bind(this),
    shouldRenderSuggestions: this.shouldRenderSuggestions.bind(this),    
    getAddressSuggestionValue: this.getAddressSuggestionValue.bind(this),
  };

  fieldRef = createRef();

  //#region VALIDATION
  // Removes event listener for validation from field
  componentWillUnmount() {
    const { validationRule } = this.props;

    if (this.fieldRef) {
      this.fieldRef.removeEventListener(
        "resetField",
        this.resetField.bind(this)
      );

      if (validationRule && Object.keys(validationRule).length > 0) {
        this.fieldRef.removeEventListener("validate", this.validate.bind(this));
      }
    }
  }

  // Adds validation event listener to field
  setRef(elem) {
    const { validationRule } = this.props;

    if (elem && this.fieldRef !== elem) {
      this.fieldRef = elem;

      elem.addEventListener("resetField", this.resetField.bind(this));

      if (!validationRule || Object.keys(validationRule).length === 0) {
        return;
      }
      elem.addEventListener("validate", this.validate.bind(this));
    }
  }

  resetField() {
    this.setState({ validationResponse: null });
  }

  validate(data) {
    const { validationRule, type, attr: { name } = {} } = this.props;
    const value =
      type === FIELD_TYPE.checkbox || type === FIELD_TYPE.radio
        ? !!this.fieldRef.checked
        : this.fieldRef.value;
    const response = validate(value, validationRule);
    const output = response !== true ? { ...response, type, name } : response;

    // If validation is called from different object you can pass object
    // to store validation error values
    if (data && data.detail && response !== true) {
      if (!data.detail.errors) {
        // eslint-disable-next-line no-param-reassign
        data.detail.errors = [];
      }
      data.detail.errors.push(output);
    }
    this.setState({ validationResponse: output });

    // if(window.location.pathname == "/checkout/shipping" && type=="password"){
    //   console.log("fieldCheck",this.fieldRef?.value)
    //   if(this.fieldRef?.value){
    //       this.props?.extractPassword(true)
    //   }else{
    //       this.props?.extractPassword(false)
    //   }
    // }

    return output;
  }

  validateOnEvent(hook, ...args) {
    if (hook) {
      const { attr, type } = this.props;
      const { value } = this.fieldRef;
      hook(
        ...[
          ...args,
          {
            ...attr,
            fieldRef: this.fieldRef,
            value,
            type,
          },
        ]
      );
    }
    this.validate();
  }
  //#endregion

  onChange(event, { newValue }) {
    this.setState({
      value: newValue,
    });
  }

  // Autosuggest will call this function every time you need to update suggestions.
  // You already implemented this logic above, so just use it.
  onSuggestionsFetchRequested({ value }) {
    const { selectedSuggestion } = this.state;

    var previousSuggest = "";

    if (selectedSuggestion && Object.entries(selectedSuggestion).length > 0) {
      previousSuggest =
        selectedSuggestion.postcode + " " + selectedSuggestion.suburb;
    }

    if (value != previousSuggest) {
      if (!isNaN(value)) {
        this.setState({ isLoading: true });

        const { defaultCountry = "AU" } = this.props;

        const inputValue = value.trim().toLowerCase();

        const inputLength = inputValue.length;

        var results = [];

        fetch("https://api.zippopotam.us/" + defaultCountry + "/" + inputValue)
          .then((response) => response.json())
          .then((data) => {
            if (data && Object.entries(data).length > 0) {
              data.places.map((place) => {
                results.push({
                  postcode: data["post code"],
                  suburb: place["place name"],
                  state: place["state abbreviation"],
                });
              });
            }

            this.setState({
              suggestions: results,
              isLoading: false,
            });
          });
      }
    }
  }

  onAddressSuggestionsFetchRequested({ value }) {
        this.setState({ isLoading: true });
        // const { defaultCountry = "AU" } = this.props;
        const inputValue = value.trim().toLowerCase();
        const inputLength = inputValue.length;
        var results = [];
        fetch(`https://api.addressify.com.au/addresspro/autocomplete?api_key=e08bbfdc-c581-4e1d-aa1f-80d448daac48&term=${inputValue}`)
          .then((response) => response.json())
          .then((response) => {
              response.map((place) => {
                // const newPostcode = place.split(", ")[1].split(" ").pop()
                // const newSuburb = place.split(", ")[1].split(" ").slice(0, -2).join(" ")
                // const newState = place.split(", ")[1].split(" ").slice(-2).reverse().pop()
                const newPostcode = place.match(/\b\d{4}\b/)?.length > 0 ? place.match(/\b\d{4}\b/)[0] : place.split(", ")[place.split(", ").length - 1].split(" ").pop()
                const newSuburb = place.split(", ")[place.split(", ").length - 1].split(" ").slice(0, -2).join(" ")
                const newState = place.split(", ")[place.split(", ").length - 1].split(" ").slice(-2).reverse().pop()
                const newAddress = place.split(", ")[0]
                if(newPostcode && newSuburb && newState){
                  results.push({
                    postcode: newPostcode,
                    suburb: newSuburb,
                    state: newState,
                    address_line1: newAddress,
                    place: place
                  });
  
                }
                });
            this.setState({
              suggestions: results,
              isLoading: false,
            });
            setTimeout(() => {
              document.getElementById("postcode").blur();
              document.getElementById("city").blur();
              document.getElementById("region_id").blur();
            }, 100);
          });
  }

  // Autosuggest will call this function every time you need to clear suggestions.
  onSuggestionsClearRequested() {
    this.setState({
      suggestions: [],
    });
  }

  // When suggestion is clicked, Autosuggest needs to populate the input
  // based on the clicked suggestion. Teach Autosuggest how to calculate the
  // input value for every given suggestion.
  getSuggestionValue(suggestion) {
    return suggestion.postcode;
  }

  getAddressSuggestionValue(suggestion) {
    return suggestion.address_line1;
  }

  // Use your imagination to render suggestions.
  renderSuggestion(suggestion) {
    return (
      <div
        className="suggestions"
        style={{
          borderTop: "1px solid #CCCCCD",
        }}
      >
            <span> {suggestion.place} </span>
      </div>
    );
  }

  onSuggestionSelected(event, { suggestion }) {
    if (suggestion.suburb != "") {
      document.getElementById("city").value = suggestion.suburb;
    }

    if (suggestion.state != "") {
      const { defaultCountry = "AU", countries } = this.props;

      const postcode = suggestion.postcode;

      const country = countries.find(({ value }) => value === defaultCountry);

      const { available_regions: availableRegions = [] } = country;

      const { id } = availableRegions.find(
        ({ code }) => code === suggestion.state
      ) || { id: null };

      document.getElementById("region_id").value = id;
      document.getElementById("postcode").value = postcode;
    }

    setTimeout(() => {
      document.getElementById("postcode").focus();
      document.getElementById("postcode").blur();
      document.getElementById("city").blur();
      document.getElementById("region_id").blur();
    }, 100);
  }

  shouldRenderSuggestions(value, reason) {
    return value.trim().length > 3;
  }
  
  containerProps() {
    const {
      events,
      validateOn,
      type,
      attr: { autoComplete, autocomplete, ...attr } = {},
      isDisabled,
      mix,
      options,
      showErrorAsLabel,
      label,
      subLabel,
      addRequiredTag,
      extraLabel,
      className,
      step,
    } = this.props;
    const {
      validationResponse,
      isLoading,
      value,
      suggestions,
      selectedSuggestion,
    } = this.state;
    const { validate } = this.containerFunctions;

    // Surrounds events with validation
    const newEvents = { ...events };
    validateOn.forEach((eventName) => {
      const { [eventName]: baseEvent } = events;
      newEvents[eventName] = baseEvent
        ? this.validateOnEvent.bind(this, baseEvent)
        : validate;
    });

    return {
      type,
      attr: {
        ...attr,
        autoComplete: autoComplete || autocomplete,
      },
      isDisabled,
      mix,
      options,
      showErrorAsLabel,
      label,
      subLabel,
      addRequiredTag,
      extraLabel,
      className,
      step,
      validationResponse,
      events: newEvents,
      fieldRef: this.fieldRef,
      setRef: this.setRef.bind(this),
      isLoading,
      value,
      suggestions,
      selectedSuggestion,
    };
  }

  render() {
    return <Field {...this.containerProps()} {...this.containerFunctions} />;
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(FieldContainer);
