/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package @scandipwa/paypal
 */

import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';

import { CartDispatcher } from 'Store/MyAccount/MyAccount.dispatcher';
import { CustomerType } from 'Type/Account';
import { isSignedIn } from 'Util/Auth';
import { fetchMutation } from 'Util/Request';
import { debounce } from 'Util/Request/Request';

import PayPalQuery from '../../query/PayPal.query';
import { PayPalStateType } from '../../type/PayPal.type';
import { getShippingMethods, saveAddressInformation } from '../../util/PayPal.util';
import InstantPayPalAddressComponent from './InstantPayPalAddress.component';
import { SHIPPING_ESTIMATE_DEBOUNCE } from './InstantPayPalAddress.config';

export const MyAccountDispatcher = import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    'Store/MyAccount/MyAccount.dispatcher'
);

export const PayPalDispatcher = import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    '../../store/PayPalPayment/PayPalPayment.dispatcher'
);

/** @namespace Paypal/Component/InstantPayPalAddress/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    payPalState: state.PayPalPaymentReducer.state,
    customer: state.MyAccountReducer.customer,
    cartTotals: state.CartReducer.cartTotals
});

/** @namespace Paypal/Component/InstantPayPalAddress/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    setPayPalState: (state) => PayPalDispatcher.then(
        ({ default: dispatcher }) => dispatcher.setPayPalState(dispatch, state)
    ),
    requestCustomerData: () => MyAccountDispatcher.then(
        ({ default: dispatcher }) => dispatcher.requestCustomerData(dispatch)
    ),
    syncCartUi: async (cartData) => CartDispatcher.then(
        ({ default: dispatcher }) => dispatcher._updateCartData(cartData, dispatch)
    )
});

/** @namespace Paypal/Component/InstantPayPalAddress/Container */
export class InstantPayPalAddressContainer extends PureComponent {
     static propTypes = {
         customer: CustomerType.isRequired,
         payPalState: PayPalStateType.isRequired,
         setPayPalState: PropTypes.func.isRequired,
         requestCustomerData: PropTypes.func.isRequired
     };

     containerFunctions = {
         onShippingEstimationFieldsChange: debounce(
             this.onShippingEstimationFieldsChange.bind(this),
             SHIPPING_ESTIMATE_DEBOUNCE
         ),
         onCheckoutAddressTableClick: this.onCheckoutAddressTableClick.bind(this),
         expandCustomAddress: this.expandCustomAddress.bind(this)
     };

     componentDidMount() {
         this.loadCustomerData();

         const {
             customer: {
                 addresses = []
             } = {},
             payPalState: {
                 shipping_address
             } = {}
         } = this.props;

         if (addresses.length) {
             const defaultShippingAddress = addresses.find(({ default_shipping }) => default_shipping);

             if (defaultShippingAddress) {
                 this.onCheckoutAddressTableClick(defaultShippingAddress);
             } else {
                 this.onCheckoutAddressTableClick({ ...shipping_address, id: -1 });
             }
         } else {
             this.onCheckoutAddressTableClick({ ...shipping_address, id: -1 });
         }
     }

     containerProps = () => {
         const {
             customer,
             payPalState,
             setPayPalState
         } = this.props;

         return {
             customer,
             payPalState,
             setPayPalState
         };
     };

     async onShippingEstimationFieldsChange(shipping_address) {
         const {
             payPalState: {
                 guest_cart_id
             } = {},
             setPayPalState
         } = this.props;

         setPayPalState({ isLoading: true });
         await getShippingMethods(shipping_address, guest_cart_id);
         setPayPalState({ isLoading: false });
     }

     async onCheckoutAddressTableClick(address) {
         const { id } = address;
         const {
             setPayPalState,
             payPalState: {
                 selectedAddressId,
                 selectedCarrier,
                 guest_cart_id,
                 shipping_address
             },
             cartTotals: {
                 shipping_method
             },
             syncCartUi
         } = this.props;

         if (id !== selectedAddressId) {
             setPayPalState({ isLoading: true });

             await saveAddressInformation(
                 {
                     shipping_address: address,
                     billing_address: address,
                     selectedCarrier
                 },
                 guest_cart_id
             );

             if (!shipping_method) {
                 const { method_code, carrier_code } = selectedCarrier;

                 if (Object.keys(shipping_address).length !== 0) {
                     if ((method_code == null || carrier_code == null) && guest_cart_id != null) {
                         this.onCheckoutAddressTableClick(address);
                     }

                     const {
                         setShippingMethodOnCart = {}
                     } = await fetchMutation(
                         PayPalQuery.getSetShippingMethodOnCartMutation({
                             guest_cart_id,
                             shipping_method: {
                                 carrier_code,
                                 method_code
                             }
                         })
                     );

                     await syncCartUi(setShippingMethodOnCart);
                 }
             }

             setPayPalState({
                 isLoading: false,
                 selectedAddressId: id
             });
         }
     }

     loadCustomerData() {
         const {
             requestCustomerData,
             customer: {
                 email
             } = {}

         } = this.props;

         if (isSignedIn() && !email) {
             requestCustomerData();
         }
     }

     expandCustomAddress() {
         const {
             setPayPalState,
             payPalState: {
                 isCustomAddressExpanded
             } = {}
         } = this.props;
         const customExpandedVal = typeof isCustomAddressExpanded === 'boolean' ? !isCustomAddressExpanded : true;

         setPayPalState({ isCustomAddressExpanded: customExpandedVal });
     }

     render() {
         const { customer = {} } = this.props;

         if (isSignedIn() && !Object.keys(customer).length) {
             return null;
         }

         return (
             <InstantPayPalAddressComponent
               containerProps={ this.containerProps.call(this) }
               containerFunctions={ this.containerFunctions }
             />
         );
     }
}

export default connect(mapStateToProps, mapDispatchToProps)(InstantPayPalAddressContainer);
