import {
    Field as SourceField,
} from 'SourceComponent/PureForm/Field/Field.component';

import './Field.override.style';

import { FIELD_TYPE } from 'Component/PureForm/Field/Field.config';

import Autosuggest from 'react-autosuggest';

import Loader from 'Component/Loader';

export class Field extends SourceField {
    
    // TODO implement logic

    renderMap = {
        // Checkboxes & Radio
        [FIELD_TYPE.radio]: this.renderCheckboxOrRadio.bind(this),
        [FIELD_TYPE.checkbox]: this.renderCheckboxOrRadio.bind(this),
        [FIELD_TYPE.multi]: this.renderCheckboxOrRadio.bind(this),

        // Default input
        [FIELD_TYPE.email]: this.renderDefaultInput.bind(this),
        [FIELD_TYPE.text]: this.renderDefaultInput.bind(this),
        [FIELD_TYPE.time]: this.renderDefaultInput.bind(this),
        [FIELD_TYPE.dateTime]: this.renderDefaultInput.bind(this),
        [FIELD_TYPE.date]: this.renderDefaultInput.bind(this),
        [FIELD_TYPE.password]: this.renderDefaultInput.bind(this),
        [FIELD_TYPE.submit]: this.renderDefaultInput.bind(this),

        // Custom fields
        [FIELD_TYPE.file]: this.renderFile.bind(this),
        [FIELD_TYPE.select]: this.renderSelect.bind(this),
        [FIELD_TYPE.textarea]: this.renderTextArea.bind(this),
        [FIELD_TYPE.button]: this.renderButton.bind(this),
        [FIELD_TYPE.number]: this.renderNumber.bind(this),
        [FIELD_TYPE.autosuggest]: this.renderAutoSuggest.bind(this),
        [FIELD_TYPE.autosuggestAddress]: this.renderAddressAutoSuggest.bind(this)

    };

    renderAutoSuggest() {
        const {
            type, setRef, attr, events, isDisabled
        } = this.props;

        const { isLoading, onChange, onSuggestionsFetchRequested, onSuggestionsClearRequested, getSuggestionValue, renderSuggestion, onSuggestionSelected, shouldRenderSuggestions } = this.props;

        const { value, suggestions, selectedSuggestion } = this.props;

        const inputProps = {
            value,
            name: 'postcode',
            onChange: onChange,
            ref :  (elem) => setRef(elem), 
            disabled :  isDisabled ,
            validationRule: {
                isRequired: true
            },
            validateOn: ['onChange', 'onBlur'],
            ...attr
        };


        return (
            <Autosuggest isLoading={ isLoading } 
                suggestions={suggestions}
                onSuggestionsFetchRequested={onSuggestionsFetchRequested}
                onSuggestionsClearRequested={onSuggestionsClearRequested}
                getSuggestionValue={getSuggestionValue}
                renderSuggestion={renderSuggestion}
                inputProps={inputProps}
                onSuggestionSelected={onSuggestionSelected}
                shouldRenderSuggestions={shouldRenderSuggestions}
            />
            
        );
    }

    renderAddressAutoSuggest() {
        const {
            type, setRef, attr, events, isDisabled
        } = this.props;
        console.log("HELLO ADDRESS")
        const { isLoading, onChange, onAddressSuggestionsFetchRequested, onSuggestionsClearRequested, getAddressSuggestionValue, renderSuggestion, onSuggestionSelected, shouldRenderSuggestions } = this.props;

        const { value, suggestions, selectedSuggestion } = this.props;

        const inputProps = {
            value: value || value === '' ? value : attr?.defaultValue,
            name: 'street',
            onChange: onChange,
            ref :  (elem) => setRef(elem), 
            disabled :  isDisabled ,
            validationRule: {
                isRequired: true
            },
            validateOn: ['onChange', 'onBlur'],
            ...attr
        };


        return (
            <Autosuggest isLoading={ isLoading } 
                suggestions={suggestions}
                onSuggestionsFetchRequested={onAddressSuggestionsFetchRequested}
                onSuggestionsClearRequested={onSuggestionsClearRequested}
                getSuggestionValue={getAddressSuggestionValue}
                renderSuggestion={renderSuggestion}
                inputProps={inputProps}
                onSuggestionSelected={onSuggestionSelected}
                shouldRenderSuggestions={shouldRenderSuggestions}
            />
            
        );
    }

    // Renders fields label above field
    renderLabel() {
        const { type, label, attr: { name } = {}, extraLabel } = this.props;

        if (!label) {
            return null;
        }

        return (
            <div block="Field" elem="LabelContainer">
                <label block="Field" elem="Label" htmlFor={ name || `input-${type}` }>
                    { label }
                    { this.renderRequiredTag() }
                </label>
                <span> { extraLabel } </span>
            </div>
        );
    }
    
    render() {
        const { type, validationResponse, mix, className, isLoading  } = this.props;
        const inputRenderer = this.renderMap[type];
        // if(window.location.pathname == "/checkout/shipping" && type=="password"){
        //     console.log("fieldCheck",type,validationResponse)
        //     if( validationResponse == true){
        //         this.props?.extractPassword(true)
        //     }else if(validationResponse?.name == "password"){
        //         this.props?.extractPassword(validationResponse?.value || null)
        //     }
        // }

        return (
            <div block="Field" elem="Wrapper" mods={ { type } }  className ={ className ? className : '' }>
                <div
                  block="Field"
                  mods={ {
                      type,
                      isValid: validationResponse === true,
                      hasError: validationResponse !== true && Object.keys(validationResponse || {}).length !== 0
                  } }
                  mix={ mix }
                >
                    { type !== FIELD_TYPE.checkbox && type !== FIELD_TYPE.radio && this.renderLabel() }
                    { inputRenderer && inputRenderer() }
                </div>
                { this.renderErrorMessages() }
                { this.renderSubLabel() }
                <Loader isLoading={ isLoading } />
            </div>
        );
    }
};

export default Field;
