import { CheckoutAddressBook as SourceCheckoutAddressBook } from "SourceComponent/CheckoutAddressBook/CheckoutAddressBook.component";

import "./CheckoutAddressBook.override.style";

// import CheckoutAddressForm from "Component/CheckoutAddressForm";
import CheckoutAddressForm from "../CheckoutAddressForm/CheckoutAddressForm.container";

import { BILLING_STEP, SHIPPING_STEP } from "Route/Checkout/Checkout.config";
import { isSignedIn } from "Util/Auth";

export class CheckoutAddressBook extends SourceCheckoutAddressBook {
  renderCustomAddress() {
    const {
      isBilling,
      onShippingEstimationFieldsChange,
      isSubmitted,
      isPickInStoreMethodSelected = false,
      checkChangeField,
    } = this.props;

    const formPortalId = isBilling ? BILLING_STEP : SHIPPING_STEP;

    return (
      <CheckoutAddressForm
        onShippingEstimationFieldsChange={onShippingEstimationFieldsChange}
        checkChangeField={(event, data) => checkChangeField(event, data)}
        address={{}}
        id={formPortalId}
        isSubmitted={isSubmitted}
        isPickInStoreMethodSelected={isPickInStoreMethodSelected}
      />
    );
  }
  renderContent() {
    const { isPickInStoreMethodSelected = false } = this.props;
    if (isSignedIn() && !isPickInStoreMethodSelected) {
      return this.renderSignedInContent();
    }

    return this.renderGuestContent();
  }
  render() {
    return <div block="CheckoutAddressBook">{this.renderContent()}</div>;
  }
}

export default CheckoutAddressBook;
