import { Popup as SourcePopup } from "SourceComponent/Popup/Popup.component";
import CloseIcon from "Component/CloseIcon";

import "./Popup.override.style";

export class Popup extends SourcePopup {
  // TODO implement logic
  renderCloseButton() {
    return (
      <button
        block="Popup"
        elem="CloseBtn"
        aria-label={__("Close")}
        onClick={this.hidePopupAndGoBack}
        style={{ zIndex: "999999" }}
      >
        <CloseIcon />
      </button>
    );
  }
}

export default Popup;