import { connect } from 'react-redux';

import MyAccountAddressForm from './MyAccountAddressForm.component';

import {
    mapStateToProps as sourceMapStateToProps,
    mapDispatchToProps as sourceMapDispatchToProps,
} from 'SourceComponent/MyAccountAddressForm/MyAccountAddressForm.container';

export const mapStateToProps = state => ({
    ...sourceMapStateToProps(state),
    // TODO extend mapStateToProps
    customer: state.MyAccountReducer.customer
});

export const mapDispatchToProps = dispatch => ({
    ...sourceMapDispatchToProps(dispatch),
    // TODO extend mapDispatchToProps
});

export default connect(mapStateToProps, mapDispatchToProps)(MyAccountAddressForm);
