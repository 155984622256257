import {
    App as SourceApp,
} from 'SourceComponent/App/App.component';

import './App.override.style';

export class App extends SourceApp {
    __construct(props) {
        super.__construct(props);

        // window.ccs_cc_args = [];
        // const scriptList = document.querySelectorAll("script[id='cnetId']");
        // const convertedNodeList_CNET = Array.from(scriptList)
        // if(convertedNodeList_CNET.length > 0){
        //     const cnetId = convertedNodeList_CNET.find(script => script.id === "cnetId")
        //     cnetId.parentNode.removeChild(cnetId)
        //     window.ccs_cc_args = []
        // }

        // testfreaks Start
        // var script = document.createElement('script');
        // script.id="teastFreaksjs";
        // script.async = true;
        // script.src = 'https://js.testfreaks.com/onpage/lmc.com.au/head.js';
        // document.head.appendChild(script);

        window.reviewRatingPDP = function(type, sku) {

            const scriptList = document.querySelectorAll("script[id='teastFreaksjs']");
            const convertedNodeList = Array.from(scriptList)
            if(convertedNodeList.length > 0){
                const testFreaksScript = convertedNodeList.find(script => script.id === "teastFreaksjs")
                testFreaksScript.parentNode.removeChild(testFreaksScript)
                window.testFreaks = []
            }


            const script = document.createElement('script');
            script.id="teastFreaksjs";
            // script.async = true;
            script.defer = true;
            script.src = 'https://js.testfreaks.com/onpage/lmc.com.au/head.js?v=2';
            document.head.appendChild(script);

            var testFreaks = window.testFreaks || [];
            testFreaks.push(["setProductId", sku]);
            testFreaks.push(["load", [type]]);
        };
        // testfreaks End
        

        // Global site tag (gtag.js) - Google Analytics => Start
        var gTagScript = document.createElement('script');
        gTagScript.id="gtag";
        // gTagScript.async = true;
        gTagScript.defer = true;
        gTagScript.src = 'https://www.googletagmanager.com/gtag/js?id=G-YBBM70BPMC';
        document.head.appendChild(gTagScript);

        window.gtagFunction = function(arg1) {
            var dataLayer = window.dataLayer || [];
            dataLayer.push(arg1);
        };

        window.gtagFunction('js', new Date());
        window.gtagFunction('config', 'G-YBBM70BPMC');


        // Global site tag (gtag.js) - Google Analytics => End

        // var ccs_cc_args = window.ccs_cc_args || [];

        // // Landmark Computers - Product Page
        // ccs_cc_args.push(['cpn', '703942']);
        // ccs_cc_args.push(['mf', 'HP']);
        // ccs_cc_args.push(['pn', '365G3PA']);
        // ccs_cc_args.push(['upcean', '365G3PA']);
        // ccs_cc_args.push(['lang', 'en']);
        // ccs_cc_args.push(['market', 'AU']);

        // (function () {
        // var o =  ccs_cc_args; 
        // o.push(['_SKey', '6a254123']); 
        // o.push(['_ZoneId', '8805d84ecb']);

        // window.ccs_cc_args = o;

        // var sc = document.createElement('script'); 
        // sc.type = "text/javascript";
        // sc.id="cnetId"; 
        // sc.async = true;
        // sc.src = ('https:' == document.location.protocol ? 'https://' : 'http://') + 'cdn.cnetcontent.com/jsc/h.js';
        // var n = document.getElementsByTagName('script')[0]; 
        // n.parentNode.insertBefore(sc, n);

        // })();
        
        this.configureAppBasedOnEnvironment();
        this.configureApp();
    }

    render() {
        // dhruvin remove following as of now
        console.log = console.warn = console.error = () => {};
        console.warn = console.error = () => {};
        // console.log("console","Compile Working...123456!")
        return this.renderContextProviders();
    }
};

export default App;
