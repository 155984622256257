import {
    ANIMATION_DURATION as SOURCE_ANIMATION_DURATION,
    NOTIFICATION_LIFETIME as SOURCE_NOTIFICATION_LIFETIME,
    ERROR_NOTIFICATION_LIFETIME as SOURCE_ERROR_NOTIFICATION_LIFETIME,
    ERROR_TYPE as SOURCE_ERROR_TYPE,
} from 'SourceComponent/Notification/Notification.config';

//TODO: implement ANIMATION_DURATION
export const ANIMATION_DURATION = SOURCE_ANIMATION_DURATION;

//TODO: implement NOTIFICATION_LIFETIME
export const NOTIFICATION_LIFETIME = 3000;

//TODO: implement ERROR_NOTIFICATION_LIFETIME
export const ERROR_NOTIFICATION_LIFETIME =5000;

//TODO: implement ERROR_TYPE
export const ERROR_TYPE = 'error';
