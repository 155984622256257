import { Notification as SourceNotification } from "SourceComponent/Notification/Notification.component";

import "./Notification.override.style";

import {
  ANIMATION_DURATION,
  ERROR_NOTIFICATION_LIFETIME,
  ERROR_TYPE,
  NOTIFICATION_LIFETIME,
} from "./Notification.config";
import CSS from "Util/CSS";


export class Notification extends SourceNotification {
  // TODO implement logic
  
  state = { isNotificationVisible: true };

  componentDidMount() {
    const {
      notification: { msgType, msgText },
      lifeTime,
    } = this.props;

    if (
      msgType == "success" &&
      msgText == "Your message has been sent and we will contact you ASAP"
    ) {
      //   CSS.setVariable(
      //     this.notification,
      //     "animation-duration",
      //     `${ANIMATION_DURATION}ms`
      //   );
      this.hideTimeout = setTimeout(
        () => this.hideNotification(),
        lifeTime || NOTIFICATION_LIFETIME
      );
      CSS.setVariable(this.notification, "animation-duration", `${0}ms`);
    } else {
      // Make sure error notification stays a little longer
      if (msgType.toLowerCase() === ERROR_TYPE) {
        this.hideTimeout = setTimeout(
          () => this.hideNotification(),
          lifeTime || ERROR_NOTIFICATION_LIFETIME
        );
      } else {
        this.hideTimeout = setTimeout(
          () => this.hideNotification(),
          lifeTime || NOTIFICATION_LIFETIME
        );
      }

      CSS.setVariable(
        this.notification,
        "animation-duration",
        `${ANIMATION_DURATION}ms`
      );
    }
  }

  componentWillUnmount() {
    // this.setState({ isNotificationVisible: false });
    // console.log("dhruvin in unmount");
    // clear started timeouts, to assure we are not changing state of unmounted component
    clearTimeout(this.hideTimeout);
    clearTimeout(this.CSSHideTimeout);
  }

  render() {
    const { notification, id } = this.props;
    const { isNotificationVisible } = this.state;
    const { msgText, msgType } = notification;

    const mods = {
      type: msgType.toLowerCase(),
      is: isNotificationVisible ? "opening" : "closing",
    };

    if (
      msgType == "success" &&
      msgText == "Your message has been sent and we will contact you ASAP"
    ) {
      return (
        <div
          block="Notification"
          className="customNotification"
          mods={mods}
          ref={this.notification}
          id={id}
        >
          <button
            block="Notification"
            elem="Button"
            onClick={this.hideNotification}
          >
            Close
          </button>
          <p block="Notification" elem="Text">
            {msgText}
          </p>
          {this.renderDebug()}
        </div>
      );
    } else if (
      msgText ==
      "The account sign-in was incorrect or your account is disabled temporarily. Please wait and try again later."
    ) {
      return (<div block="Notification" mods={mods} ref={this.notification} id={id} className="customStyleForMesgSignInFail">
        <button
          block="Notification"
          elem="Button"
          onClick={this.hideNotification}
        >
          Close
        </button>
        <p block="Notification" elem="Text">
          {msgText}
        </p>
        {this.renderDebug()}
      </div>);
      } else {
      return (
        <div block="Notification" mods={mods} ref={this.notification} id={id}>
          <button
            block="Notification"
            elem="Button"
            onClick={this.hideNotification}
          >
            Close
          </button>
          <p block="Notification" elem="Text">
            {msgText}
          </p>
          {this.renderDebug()}
        </div>
      );
    }
  }
}

export default Notification;
