import { connect } from 'react-redux';

import {
    mapStateToProps as sourceMapStateToProps,
    mapDispatchToProps as sourceMapDispatchToProps,
} from 'SourceComponent/CheckoutAddressForm/CheckoutAddressForm.container';

import CheckoutAddressForm from './CheckoutAddressForm.component';

export const mapStateToProps = state => ({
    ...sourceMapStateToProps(state),
    // TODO extend mapStateToProps
});

export const mapDispatchToProps = dispatch => ({
    ...sourceMapDispatchToProps(dispatch),
    // TODO extend mapDispatchToProps
});

export default connect(mapStateToProps, mapDispatchToProps)(CheckoutAddressForm);
