/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import CategoriesReducer from '@scandipwa/blog/src/store/Categories/Categories.reducer';
import PostsReducer from '@scandipwa/blog/src/store/Posts/Posts.reducer';
import PostsDetailsReducer from '@scandipwa/blog/src/store/PostsDetails/PostsDetails.reducer';
import PostsSearchReducer from '@scandipwa/blog/src/store/PostsSearch/PostsSearch.reducer';

export class StoreIndexPlugin {
    /**
     * Plugin to add blog reducers to getStaticReducers method.
     * @param args
     */

    getStaticReducers = (args, callback) => ({
        ...callback(...args),
        CategoriesReducer,
        PostsDetailsReducer,
        PostsReducer,
        PostsSearchReducer
    });
}

const { getStaticReducers } = new StoreIndexPlugin();

export const config = {
    'Store/Index/getStaticReducers': {
        function: getStaticReducers
    }
};

export default config;
