/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

// dhruvin fb -1 (add this folder)

import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import Field from 'Component/PureForm/Field';
import FIELD_TYPE from 'Component/PureForm/Field/Field.config';
import { PaymentMethodType } from 'Type/Checkout';
import CmsBlock from 'Component/CmsBlock';

import './CheckoutPayment.style';

/** @namespace Component/CheckoutPayment/Component */
export class CheckoutPayment extends PureComponent {
    static propTypes = {
        method: PaymentMethodType.isRequired,
        onClick: PropTypes.func.isRequired,
        isSelected: PropTypes.bool
    };

    static defaultProps = {
        isSelected: false
    };

    onClick = () => {
        const {
            onClick,
            method
        } = this.props;

        onClick(method);
    };
    renderTitleOfPaymentOption = () => {
        const {
            isSelected,
            method: { title },
            isWallet = false,
            device
        } = this.props;

        if(title == "Wallet Order"){
            if(device?.android){
                return <svg xmlns="http://www.w3.org/2000/svg" width="70" height="25" viewBox="0 -0.02 2387.3 948.02"><path d="M1129.1 463.2V741h-88.2V54.8h233.8c56.4-1.2 110.9 20.2 151.4 59.4 41 36.9 64.1 89.7 63.2 144.8 1.2 55.5-21.9 108.7-63.2 145.7-40.9 39-91.4 58.5-151.4 58.4l-145.6.1zm0-323.9v239.6h147.8c32.8 1 64.4-11.9 87.2-35.5 46.3-45 47.4-119.1 2.3-165.4-.8-.8-1.5-1.6-2.3-2.3-22.5-24.1-54.3-37.3-87.2-36.4h-147.8zm563.4 116.9c65.2 0 116.6 17.4 154.3 52.2 37.7 34.8 56.5 82.6 56.5 143.2V741H1819v-65.2h-3.8c-36.5 53.7-85.1 80.5-145.7 80.5-51.7 0-95-15.3-129.8-46-33.8-28.5-53-70.7-52.2-115 0-48.6 18.4-87.2 55.1-115.9 36.7-28.7 85.7-43.1 147.1-43.1 52.3 0 95.5 9.6 129.3 28.7v-20.2c.2-30.2-13.2-58.8-36.4-78-23.3-21-53.7-32.5-85.1-32.1-49.2 0-88.2 20.8-116.9 62.3l-77.6-48.9c42.6-61.3 105.8-91.9 189.5-91.9zm-114.1 341.1c-.1 22.8 10.8 44.2 29.2 57.5 19.5 15.3 43.7 23.5 68.5 23 37.2-.1 72.9-14.9 99.2-41.2 29.2-27.5 43.8-59.7 43.8-96.8-27.5-21.9-65.8-32.9-115-32.9-35.8 0-65.7 8.6-89.6 25.9-24.1 17.6-36.1 38.9-36.1 64.5zm808.9-325.8L2093 948h-91l109.2-236.7-193.6-439.8h95.8l139.9 337.3h1.9l136.1-337.3h96z" style={{fill:"#5f6368"}}/><path d="M772.8 403.2c0-26.9-2.2-53.7-6.8-80.2H394.2v151.8h212.9c-8.8 49-37.2 92.3-78.7 119.8v98.6h127.1c74.4-68.5 117.3-170 117.3-290z" style={{fill:"#4285f4"}}/><path d="M394.2 788.5c106.4 0 196-34.9 261.3-95.2l-127.1-98.6c-35.4 24-80.9 37.7-134.2 37.7-102.8 0-190.1-69.3-221.3-162.7H42v101.6c66.9 133.2 203.2 217.2 352.2 217.2z" style={{fill:"#34a853"}}/><path d="M172.9 469.7c-16.5-48.9-16.5-102 0-150.9V217.2H42c-56 111.4-56 242.7 0 354.1l130.9-101.6z" style={{fill:"#fbbc04"}}/><path d="M394.2 156.1c56.2-.9 110.5 20.3 151.2 59.1L658 102.7C586.6 35.7 492.1-1.1 394.2 0 245.2 0 108.9 84.1 42 217.2l130.9 101.6c31.2-93.4 118.5-162.7 221.3-162.7z" style={{fill:"#ea4335"}}/></svg>
            }else if(device?.ios || device?.safari){
                return <svg xmlns="http://www.w3.org/2000/svg" width="70" height="25" viewBox="0 9.33 32 13.29"><path d="M5.849 11.047c-.376.448-.975.803-1.573.751-.079-.604.219-1.251.563-1.652.375-.457 1.031-.785 1.563-.812.063.631-.183 1.251-.552 1.713zm.547.87c-.869-.053-1.615.499-2.027.499-.421 0-1.052-.473-1.739-.457-.891.011-1.724.52-2.177 1.339-.943 1.629-.245 4.041.661 5.369.443.656.973 1.375 1.672 1.355.661-.027.927-.437 1.724-.437.807 0 1.036.437 1.74.421.723-.011 1.181-.656 1.624-1.312.505-.745.713-1.475.724-1.511-.011-.016-1.401-.552-1.411-2.167-.011-1.355 1.093-2 1.14-2.037-.62-.937-1.599-1.036-1.932-1.061zm5.016-1.834v9.855h1.515v-3.369h2.095c1.911 0 3.255-1.328 3.255-3.245 0-1.921-1.317-3.24-3.203-3.24zm1.515 1.292h1.745c1.312 0 2.063.708 2.063 1.953s-.751 1.959-2.073 1.959h-1.735zm8.109 8.636c.953 0 1.833-.484 2.235-1.256h.032v1.183h1.4v-4.907c0-1.416-1.124-2.337-2.859-2.337-1.604 0-2.792.932-2.833 2.208h1.359c.115-.609.667-1.005 1.433-1.005.927 0 1.443.437 1.443 1.24v.541l-1.885.115c-1.761.109-2.709.833-2.709 2.099 0 1.276.98 2.12 2.385 2.12zm.412-1.167c-.808 0-1.323-.391-1.323-.989 0-.62.495-.985 1.437-1.043l1.683-.104v.557c0 .923-.776 1.579-1.803 1.579zm5.125 3.776c1.473 0 2.167-.573 2.771-2.297L32 12.792h-1.536l-1.781 5.817h-.032l-1.781-5.817h-1.583l2.563 7.172-.136.437c-.235.735-.609 1.02-1.276 1.02-.12 0-.349-.015-.443-.025v1.183c.088.025.464.036.573.036z"/></svg>
            }
            return title;
        }
        return title;
    };

    render() {
        const {
            isSelected,
            method: { title },
            isWallet = false
        } = this.props;
        console.log("title", this.props)
        if(this.props.method?.code == "stripe_payments" && isWallet && isSelected){
            return <li block="CheckoutPayment">
                <button
                block="CheckoutPayment"
                mods={ { isSelected } }
                elem="Button"
                type="button"
                onClick={ this.onClick }
                >
                    <Field
                    type={ FIELD_TYPE.checkbox }
                    attr={ {
                        id: `option-${ title }`,
                        name: `option-${ title }`,
                        checked: isSelected
                    } }
                    isDisabled
                    />
                    {this.renderTitleOfPaymentOption()}
                </button>
                <div className='hideStripeElement'>
                {this.props?.render() && this.props.render()}
                </div>
            </li>
        }

        
        // disable checkbox in order to skip direct clicks on checkbox and handle clicks on entire button instead
        return ((this.props.method?.code == "stripe_payments") && isSelected) ?
                        <li block="CheckoutPayment">
                        <button
                        block="CheckoutPayment"
                        mods={ { isSelected } }
                        elem="Button"
                        type="button"
                        onClick={ this.onClick }
                        >
                            <Field
                            type={ FIELD_TYPE.checkbox }
                            attr={ {
                                id: `option-${ title }`,
                                name: `option-${ title }`,
                                checked: isSelected
                            } }
                            isDisabled
                            />
                            { title }
                        </button>
                        <div className='hideButtonStrip'>
                        {this.props.render()}
                        </div>
                    </li>
                    :
                    <li block="CheckoutPayment">
                    <button
                    block="CheckoutPayment"
                    mods={ { isSelected } }
                    elem="Button"
                    type="button"
                    onClick={ this.onClick }
                    >
                        <Field
                        type={ FIELD_TYPE.checkbox }
                        attr={ {
                            id: `option-${ title }`,
                            name: `option-${ title }`,
                            checked: isSelected
                        } }
                        isDisabled
                        />
                        {this.renderTitleOfPaymentOption()}
                    </button>
                    {this.props.method?.code == 'banktransfer' && <CmsBlock identifier='bank_transfer_instruction' key="bank-transfer-instruction" type="bank-transfer-instruction" />}
                    </li>
    }
}

export default CheckoutPayment;
