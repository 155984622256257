/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import FIELD_TYPE from "Component/PureForm/Field/Field.config";
import { VALIDATION_INPUT_TYPE } from "Util/Validator/Config";

/**
 * Returns fields for street
 * @param props
 * @returns {[{addRequiredTag: boolean, validateOn: string[], validationRule: {isRequired: boolean}, label: *, type: string, attr: {defaultValue: (*|string), name: string, placeholder: *}}]|*[]}
 * @namespace Component/MyAccountAddressForm/Form/getStreetFields
 */
export const getStreetFields = (props, events) => {
  const {
    id,
    addressLinesQty = 1,
    address: { street = [] },
  } = props;

  var titleCap = "Street";
  var titleSmall = "Street";

  if (id == "SHIPPING_STEP") {
    titleCap = "Delivery";
    titleSmall = "delivery";
  }
  var addressType = FIELD_TYPE.autosuggestAddress;

  if (id == "SHIPPING_STEP") {
    var addressType = FIELD_TYPE.autosuggestAddress;
  }

  if (addressLinesQty === 1) {
    return [
      {
        type: addressType,
        label: __(`${titleCap} address`),
        attr: {
          name: "street",
          defaultValue: street[0] || "",
          placeholder: __(`Your ${titleSmall} address`),
          tabIndex: 5,
        },
        events: {
          onChange: events.onChangeCheck,
          onBlur: events.onChangeCheck,
        },
        addRequiredTag: true,
        validateOn: ["onChange", "onBlur"],
        validationRule: {
          isRequired: true,
        },
        className: "full-width",
      },
    ];
  }

  const streets = [];

  // eslint-disable-next-line fp/no-loops, fp/no-let
  for (let i = 0; i < addressLinesQty; i++) {
    streets.push({
      type: FIELD_TYPE.text,
      label: __(`${titleCap} address line %s`, i + 1),
      attr: {
        name: `street_${i}`,
        defaultValue: street[i] || "",
        placeholder: __(`Your ${titleSmall} address line %s`, i + 1),
      },
      addRequiredTag: i === 0,
      validateOn: i === 0 ? ["onChange"] : [],
      validationRule: {
        isRequired: i === 0,
      },
    });
  }

  return streets;
};

/**
 * Returns region fields
 * @param props
 * @returns {[{addRequiredTag: boolean, validateOn: (string[]|*[]), validationRule: {isRequired}, options, label: *, type: string, attr: {defaultValue: number, name: string, selectPlaceholder: *}}]|*[]|[{validateOn: (string[]|*[]), validationRule: {isRequired}, label: *, type: string, attr: {defaultValue, name: string, id: string, placeholder: *}}]}
 * @namespace Component/MyAccountAddressForm/Form/getRegionFields
 */
export const getRegionFields = (props) => {
  const {
    region: { region, region_id: regionId = 1 } = {},
    regionDisplayAll,
    availableRegions,
    isStateRequired,
  } = props;

  if (!regionDisplayAll && !isStateRequired) {
    return [];
  }

  if (!availableRegions || !availableRegions.length) {
    return [
      {
        type: FIELD_TYPE.text,
        label: __("State / Province"),
        attr: {
          id: "address-region-id",
          name: "region_string",
          defaultValue: region,
          placeholder: __("Your state / province"),
          tabIndex: 9,
        },
        addRequiredTag: true || isStateRequired,
        validateOn: true || isStateRequired ? ["onChange"] : [],
        validationRule: {
          isRequired: true || isStateRequired,
        },
      },
    ];
  }

  return [
    {
      type: FIELD_TYPE.select,
      label: __("State / Province"),
      attr: {
        name: "region_id",
        id: "region_id",
        defaultValue: regionId,
        selectPlaceholder: __("Select region..."),
        tabIndex: 9,
      },
      options: availableRegions.map(({ id, name }) => ({
        id,
        label: name,
        value: id,
      })),
      addRequiredTag: true || isStateRequired,
      validateOn: true || isStateRequired ? ["onChange"] : [],
      validationRule: {
        isRequired: true || isStateRequired,
      },
      className: "full-width",
    },
  ];
};

/**
 * Returns VAT fields
 * @param props
 * @returns {*[]|[{label: *, type: string, attr: {defaultValue: string, name: string}}]}
 * @namespace Component/MyAccountAddressForm/Form/getVatFields
 */
export const getVatFields = (props) => {
  const { showVatNumber, vat_id: vatID = "" } = props;

  if (!showVatNumber) {
    return [];
  }

  return [
    {
      type: FIELD_TYPE.text,
      label: __("VAT Number"),
      attr: {
        name: "vat_number",
        defaultValue: vatID,
      },
    },
  ];
};

/**
 * Returns address form fields
 * @param props
 * @param events
 * @returns {[{label: *, type: string, attr: {defaultChecked, name: string}}, {label: *, type: string, attr: {defaultChecked, name: string}}, {addRequiredTag: boolean, validateOn: string[], validationRule: {isRequired: boolean}, label: *, type: string, attr: {defaultValue: string, name: string, placeholder: *}}, {addRequiredTag: boolean, validateOn: string[], validationRule: {isRequired: boolean}, label: *, type: string, attr: {defaultValue: string, name: string, placeholder: *}}, {mods: {street: boolean, multipleFields: boolean, oddAddresses: boolean}, name: string, fields: ({addRequiredTag: boolean, validateOn: string[], validationRule: {isRequired: boolean}, label: *, type: string, attr: {defaultValue: (*|string), name: string, placeholder: *}}[]|*[])}, null, ...*[]|{label: *, type: string, attr: {defaultValue: string, name: string}}[], null]}
 * @namespace Component/MyAccountAddressForm/Form/myAccountAddressForm
 */
export const myAccountAddressForm = (props, events = {}) => {
  const {
    id,
    isPickInStoreMethodSelected = false,
    default_billing: defaultBilling,
    default_shipping: defaultShipping,
    countryId,
    firstname = "",
    lastname = "",
    city = "",
    countries = [],
    postcode = "",
    telephone = "",
    addressLinesQty = 1,
    company = "",
    firstnameDefaultAddress,
    lastnameDefaultAddress,
    telephoneDefaultAddress,
  } = props;

  if (isPickInStoreMethodSelected) {
    return [
      {
        type: FIELD_TYPE.checkbox,
        label: __("This is default Billing Address"),
        attr: {
          name: "default_billing",
          defaultChecked: defaultBilling,
        },
      },
      {
        type: FIELD_TYPE.checkbox,
        label: __("This is default Shipping Address"),
        attr: {
          name: "default_shipping",
          defaultChecked: defaultShipping,
        },
      },
      {
        type: FIELD_TYPE.text,
        label: __("First name"),
        attr: {
          name: "firstname",
          defaultValue: firstnameDefaultAddress,
          placeholder: __("Your first name"),
        },
        addRequiredTag: true,
        validateOn: ["onChange"],
        validationRule: {
          isRequired: true,
        },
      },
      {
        type: FIELD_TYPE.text,
        label: __("Last name"),
        attr: {
          name: "lastname",
          defaultValue: lastnameDefaultAddress,
          placeholder: __("Your last name"),
        },
        addRequiredTag: true,
        validateOn: ["onChange"],
        validationRule: {
          isRequired: true,
        },
      },
      {
        type: FIELD_TYPE.text,
        label: __("Phone number"),
        attr: {
          name: "telephone",
          defaultValue: telephoneDefaultAddress,
          placeholder: __("Your phone number"),
        },
        addRequiredTag: true,
        validateOn: ["onChange"],
        validationRule: {
          inputType: VALIDATION_INPUT_TYPE.phone,
          isRequired: true,
        },
        className: "full-width",
      },
    ];
  }

  const { onCountryChange, onZipcodeBlur, onChangeCheck } = events || {};

  var postcodeLabel = "Postcode";
  var postcodeFieldType = FIELD_TYPE.text;
  if (id == "SHIPPING_STEP") {
    var postcodeLabel = "Enter Postcode to select Shipping Method";
  }

  return [
    {
      type: FIELD_TYPE.checkbox,
      label: __("This is default Billing Address"),
      attr: {
        name: "default_billing",
        defaultChecked: defaultBilling,
      },
    },
    {
      type: FIELD_TYPE.checkbox,
      label: __("This is default Shipping Address"),
      attr: {
        name: "default_shipping",
        defaultChecked: defaultShipping,
      },
    },
    {
      type: FIELD_TYPE.text,
      label: __("First name"),
      attr: {
        name: "firstname",
        defaultValue: firstname,
        placeholder: __("Your first name"),
        tabIndex: 2,
      },
      events: {
        onChange: onChangeCheck,
      },
      addRequiredTag: true,
      validateOn: ["onChange"],
      validationRule: {
        isRequired: true,
      },
    },
    {
      type: FIELD_TYPE.text,
      label: __("Last name"),
      attr: {
        name: "lastname",
        defaultValue: lastname,
        placeholder: __("Your last name"),
        tabIndex: 3,
      },
      events: {
        onChange: onChangeCheck,
      },
      addRequiredTag: true,
      validateOn: ["onChange"],
      validationRule: {
        isRequired: true,
      },
    },
    {
      type: FIELD_TYPE.text,
      label: __("Phone number (Including Area Code)"),
      attr: {
        name: "telephone",
        defaultValue: telephone,
        placeholder: __("Your phone number (Including Area Code)"),
        tabIndex: 4,
      },
      events: {
        onChange: onChangeCheck,
      },
      addRequiredTag: true,
      validateOn: ["onChange"],
      validationRule: {
        inputType: VALIDATION_INPUT_TYPE.phone,
        isRequired: true,
      },
    },
    {
      type: FIELD_TYPE.text,
      label: __("Company (optional)"),
      attr: {
        name: "company",
        defaultValue: company,
        placeholder: __("Your company name"),
        tabIndex: 5,
      },
      events: {
        onChange: onChangeCheck,
      },
      addRequiredTag: false,
      validateOn: ["onChange"],
      validationRule: {
        isRequired: false,
      },
    },
    {
      name: "streetGroup",
      mods: {
        street: true,
        multipleFields: addressLinesQty > 0,
        oddAddresses: addressLinesQty % 2 === 1,
      },
      fields: getStreetFields(props, events),
    },
    {
      name: "addressGroup",
      mods: { address: true },
      fields: [
        {
          type: postcodeFieldType,
          label: postcodeLabel,
          attr: {
            name: "postcode",
            id: "postcode",
            defaultValue: postcode,
            placeholder: postcodeLabel,
            tabIndex: 6,
            maxlength: "4",
          },
          events: {
            onBlur: onZipcodeBlur,
            onChange: onChangeCheck,
          },
          addRequiredTag: true,
          validateOn: ["onChange", "onBlur"],
          validationRule: {
            inputType: VALIDATION_INPUT_TYPE.numeric,
            isRequired: true,
          },
          className: "full-width",
        },
        {
          type: FIELD_TYPE.text,
          label: __("Suburb"),
          attr: {
            name: "city",
            id: "city",
            defaultValue: city,
            placeholder: __("Your suburb"),
            tabIndex: 7,
          },
          events: {
            onChange: onChangeCheck,
          },
          addRequiredTag: true,
          validateOn: ["onChange"],
          validationRule: {
            isRequired: true,
          },
          className: "full-width",
        },
        {
          type: FIELD_TYPE.select,
          label: __("Country"),
          attr: {
            id: "address-country-id",
            name: "country_id",
            defaultValue: countryId,
            selectPlaceholder: __("Select country..."),
            tabIndex: 8,
          },
          events: {
            onChange: onCountryChange,
          },
          options: countries,
          addRequiredTag: true,
          validateOn: ["onChange"],
          validationRule: {
            isRequired: true,
          },
          className: "d-none",
        },
        ...getRegionFields(props, events),
      ],
    },
    ...getVatFields(props),
  ];
};

export default myAccountAddressForm;
