
import Field from 'Component/PureForm/Field';
import FIELD_TYPE from 'Component/PureForm/Field/Field.config';
import Form from 'Component/PureForm/Form';
import { VALIDATION_INPUT_TYPE } from 'Util/Validator/Config';

export const SOCIAL_LOGIN_PROVIDERS = {
    google: 'GOOGLE ACCOUNT',
    facebook: 'FACEBOOK'
};

/** @namespace SocialLogin/Plugin/MyAccountSignIn/Component/MyAccountSignInPlugin */
export class MyAccountSignInPlugin {
    // eslint-disable-next-line @scandipwa/scandipwa-guidelines/only-render-in-component
    getSocialLogins(instance) {
        const { logins, isSocialLoginsLoading } = instance.props;

        if (isSocialLoginsLoading) {
            return (
                <>
                    <div block="MyAccountOverlay" elem="Provider" mods={ { loading: true } } />
                    <div block="MyAccountOverlay" elem="Provider" mods={ { loading: true } } />
                </>
            );
        }

        if (logins) {
            return logins.map(({ url, provider }) => (
                <div block="MyAccountOverlay" elem="Provider" key={ provider }>
                    <a href={ url } block="MyAccountOverlay" elem={ provider } mix={ { block: 'Button' } }>
                        <span>{ SOCIAL_LOGIN_PROVIDERS[provider] }</span>
                    </a>
                </div>
            ));
        }

        return false;
    }

    renderSocialLogins(instance) {
        const { logins } = instance.props;

        if (logins && logins.length === 0) {
            return null;
        }

        return (
            <div block="MyAccountOverlay" elem="Social">
                <h4 id="social-login"><span>{ __('Or sign in with') }</span></h4>
                { this.getSocialLogins(instance) }
            </div>
        );
    }

    renderAdditionalField(instance) {
        const {
            state,
            handleSignIn,
            handleCreateAccount,
            isCheckout,
        } = instance.props;

        if (!isCheckout) {

            return (
                <article block="MyAccountOverlay" elem="Additional" mods={ { state } }>
                    <section>
                        <h4 id="forgot-password-label">{ __("Don't have an account?") }</h4>
                        <button
                          block="Button"
                          mods={ { likeLink: true } }
                          onClick={ handleCreateAccount }
                        >
                            { __('Create an account') }
                        </button>
                    </section>
                </article>
            );
            
        }

        return (
            <article block="MyAccountOverlay" elem="Additional" mods={ { state } }>
                <section>
                    <h4 id="forgot-password-label">{ __('Already have an account?') }</h4>
                    <button
                      block="Button"
                      mods={ { likeLink: true } }
                      onClick={ handleSignIn }
                    >
                        { __('Sign In') }
                    </button>
                </section>
            </article>
        );
    }

    renderSignInForm(instance) {
        
        const {
            onSignInSuccess,
            onFormError,
            handleForgotPassword,
            emailValue,
            isCheckout
        } = instance.props;

        var buttonText = __('Sign in') ;

        if(isCheckout)
        {
            buttonText = __('Continue') ;
        } 
        
        const extractPassword = (password) => {
            // if(window.location.pathname == "/checkout/shipping"){
            //     if(password){
            //         updateContent(true)
            //     }else{
            //         updateContent(false)
            //     }
            // }
        }
        const updateContent = (isIt) => {
            const dynamicContent = document.getElementById('dynamicContent');
            const isPasswordFilled = isIt;

            if(dynamicContent && window.location.pathname == "/checkout/shipping"){
                if (isPasswordFilled) {
                    dynamicContent.innerHTML = `<button class='Button'>${buttonText}</button>`;
                } else {
                    dynamicContent.innerHTML = `
                        <div id='scrollingId' class='Button' onclick="window.scrollTo({top: 350,behavior: 'smooth'})">
                            ${buttonText}
                        </div>`;
                }
            }
        };

        return (
            <>
            <Form
              key="sign-in"
              onSubmit={ onSignInSuccess }
              onError={ onFormError }
            >
                <Field
                  label={ __('Email Address') }
                  type={ FIELD_TYPE.email }
                  attr={ {
                      id: 'email',
                      name: 'email',
                      placeholder: __('email address'),
                      defaultValue: emailValue,
                      autocomplete: isCheckout ? 'off' : 'email'
                  } }
                  validateOn={ ['onChange'] }
                  validationRule={ {
                      isRequired: true,
                      inputType: VALIDATION_INPUT_TYPE.email
                  } }
                  addRequiredTag
                />
                <Field
                  label={ __('Password') }
                  type={ FIELD_TYPE.password }
                  attr={ {
                      id: 'password',
                      name: 'password',
                      placeholder: __('Enter your password'),
                      autocomplete: 'current-password'
                  } }
                  validateOn={ ['onChange'] }
                  validationRule={ {
                      isRequired: true,
                      inputType: VALIDATION_INPUT_TYPE.password
                  } }
                  addRequiredTag={true}
                  extractPassword={extractPassword}
                />
                <button
                  type="button"
                  block="Button"
                  mods={ { likeLink: true } }
                  mix={ { block: 'MyAccountOverlay', elem: 'ForgotPassword' } }
                  onClick={ handleForgotPassword }
                >
                    { __('Forgot password?') }
                </button>
                <div block="MyAccountOverlay" elem="SignInButton">
                {/* {window.location.pathname == "/checkout/shipping" ? (
                <span id="dynamicContent">
                    <div
                    id="scrollingId"
                    block="Button"
                    onClick={() =>
                        window.scrollTo({
                        top:
                            document.getElementById("scrollingId").offsetTop + 300,
                        behavior: "smooth",
                        })
                    }
                    >
                    {buttonText}
                    </div>
                </span>
                ) : ( */}
                <button block="Button">{buttonText}</button>
                {/* )} */}
                </div>
            </Form>
            </>
        );
    }

    render = (args, callback, instance) => (
        <>
            <div className="guest-email">
            <div style={{ paddingTop: "7px" }}>
                    <div style={{ paddingBottom: "3px" }}>Dear Valued Customer,</div>
                    <div>We have recently updated our website and you will need to re-register to access your new account. 
                    If you need access to your orders, quotes and invoice, please email us at <a style={{ fontWeight: "400", fontSize: "12px" }} href="mailto:sales@lmc.com.au">sales@lmc.com.au</a></div>
                    <div style={{ paddingTop: "1px" }}>Alternatively you can call us on 1300 133 165 (Monday to Friday 9.00am to 5.00pm).</div>
                </div>
                { this.renderSignInForm(instance) }
            </div>
            {!instance.props?.isCheckout && <div className="social-logins">
                { this.renderAdditionalField(instance) }
                { this.renderSocialLogins(instance) }
            </div> }    
        </>
    );
}

const { render } = new MyAccountSignInPlugin();

export const config = {
    'Component/MyAccountSignIn/Component': {
        'member-function': {
            render: [
                {
                    position: 110,
                    implementation: render
                }
            ]
        }
    }
};

export default config;
