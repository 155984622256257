import {
    IN_STOCK as SOURCE_IN_STOCK,
    OUT_OF_STOCK as SOURCE_OUT_OF_STOCK,
    PRODUCT_TYPE as SOURCE_PRODUCT_TYPE,
} from 'SourceComponent/Product/Product.config';

//TODO: implement IN_STOCK
export const IN_STOCK = SOURCE_IN_STOCK;

//TODO: implement OUT_OF_STOCK
export const OUT_OF_STOCK = SOURCE_OUT_OF_STOCK;

//TODO: implement PRODUCT_TYPE
export const PRODUCT_TYPE = SOURCE_PRODUCT_TYPE;

export default PRODUCT_TYPE;
