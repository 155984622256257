import { connect } from 'react-redux';

import {
    mapStateToProps as sourceMapStateToProps,
    mapDispatchToProps as sourceMapDispatchToProps,
    PopupContainer as SourcePopupContainer,
} from 'SourceComponent/Popup/Popup.container';

export const mapStateToProps = state => ({
    ...sourceMapStateToProps(state),
    // TODO extend mapStateToProps
});

export const mapDispatchToProps = dispatch => ({
    ...sourceMapDispatchToProps(dispatch),
    // TODO extend mapDispatchToProps
});

export class PopupContainer extends SourcePopupContainer {
    // TODO implement logic
    containerProps() {
            const {
                activeOverlay,
                areOtherOverlaysOpen,
                changeHeaderState,
                children,
                id,
                isMobile,
                isStatic,
                mix,
                contentMix,
                onClose,
                onHide,
                onVisible,
                shouldPopupClose,
                hideActiveOverlay,
                resetHideActivePopup,
                goToPreviousNavigationState,
                clickOutside
            } = this.props;

            return {
                activeOverlay,
                areOtherOverlaysOpen,
                changeHeaderState,
                children,
                id,
                isMobile,
                isStatic,
                mix,
                contentMix,
                shouldPopupClose,
                onClose,
                onHide,
                onVisible,
                hideActiveOverlay,
                resetHideActivePopup,
                goToPreviousNavigationState,
                clickOutside,
                title: this._getPopupTitle()
            };
        }
};

export default connect(mapStateToProps, mapDispatchToProps)(PopupContainer);
