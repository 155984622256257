import { connect } from 'react-redux';

import {
    mapStateToProps as sourceMapStateToProps,
    mapDispatchToProps as sourceMapDispatchToProps,
    RouterContainer as SourceRouterContainer,
} from 'SourceComponent/Router/Router.container';

export const mapStateToProps = state => ({
    ...sourceMapStateToProps(state),
    // TODO extend mapStateToProps
});

export const mapDispatchToProps = dispatch => ({
    ...sourceMapDispatchToProps(dispatch),
    // TODO extend mapDispatchToProps
});

export class RouterContainer extends SourceRouterContainer {
    // TODO implement logic
};

export default connect(mapStateToProps, mapDispatchToProps)(RouterContainer);
