import {
    MyAccountAddressForm as SourceMyAccountAddressForm,
} from 'SourceComponent/MyAccountAddressForm/MyAccountAddressForm.component';

import './MyAccountAddressForm.override.style';

import myAccountAddressForm from './MyAccountAddressForm.form';

export class MyAccountAddressForm extends SourceMyAccountAddressForm {
    // TODO implement logic
    
    get fieldMap() {
        
        const {
            id,
            isPickInStoreMethodSelected,
            address,
            countries,
            addressLinesQty,
            regionDisplayAll,
            showVatNumber,
            defaultCountry
        } = this.props;

        const {
            availableRegions,
            isStateRequired,
            countryId,
            firstnameDefaultAddress= this.props?.customer?.addresses &&  this.props?.customer?.addresses[0]?.firstname,
            lastnameDefaultAddress= this.props?.customer?.addresses &&  this.props?.customer?.addresses[0]?.lastname,
            telephoneDefaultAddress= this.props?.customer?.addresses &&  this.props?.customer?.addresses[0]?.telephone
        } = this.state;

        return myAccountAddressForm({
            id,
            isPickInStoreMethodSelected,
            address,
            countries,
            addressLinesQty,
            regionDisplayAll,
            showVatNumber,
            defaultCountry,
            availableRegions,
            isStateRequired,
            countryId,
            firstnameDefaultAddress,
            lastnameDefaultAddress,
            telephoneDefaultAddress,
            ...address
        }, {
            onCountryChange: this.onCountryChange,
            onZipcodeBlur: this.onZipcodeBlur
        });
    }
};

export default MyAccountAddressForm;
