/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

export const GET_STOCK_LIST = "GET_STOCK_LIST";

/** @namespace Store/StockGet/getStockList */
export const getStockList = (payload) => ({
  type: GET_STOCK_LIST,
  payload,
});
