import {
    ProductPage as SourceProductPage,
    CategoryPage as SourceCategoryPage,
    CmsPage as SourceCmsPage,
    NoMatch as SourceNoMatch,
    UrlRewrites as SourceUrlRewrites,
} from 'SourceRoute/UrlRewrites/UrlRewrites.component';

import './UrlRewrites.override.style';
import {  Suspense } from 'react';
import Loader from 'Component/Loader';

//TODO: implement ProductPage
export const ProductPage = SourceProductPage;

//TODO: implement CategoryPage
export const CategoryPage = SourceCategoryPage;

//TODO: implement CmsPage
export const CmsPage = SourceCmsPage;

//TODO: implement NoMatch
export const NoMatch = SourceNoMatch;

export class UrlRewrites extends SourceUrlRewrites {
    // TODO implement logic

    renderDefaultPage() {
        return (
            <div className="UrlRewrites" style={{height:'400px', width: '50%', margin : 'auto'}}>
                <Loader />
            </div>
        );
    }

    render() {
        return (
            <Suspense fallback={  this.renderDefaultPage()  }>
                { this.renderContent() }
            </Suspense>
        );
    }
};

export default UrlRewrites;
