import {
    ProductListQuery as SourceProductListQuery,
} from 'SourceQuery/ProductList.query';

import { Field, Fragment } from 'Util/Query';

export class ProductListQuery extends SourceProductListQuery {
    // TODO implement logic

    getQuery(options) {
        if (!options) {
            throw new Error('Missing argument `options`');
        }

        this.options = options;

        console.log("_getProductArguments",this._getProductsField())
        console.log("_getProductArguments_options",options)
        return this._getProductsField();
    }

     _getProductInterfaceFields(isVariant, isForLinkedProducts = false) {
        const {
            isPlp = false,
            isSingleProduct,
            noAttributes = false,
            noVariants = false,
            noVariantAttributes = false
        } = this.options;

        // Basic fields returned always
        const fields = [
            'uid',
            'id',
            'sku',
            'weight',
            'height_mm',
            'length_mm',
            'width_mm',
            'name',
            'type_id',
            'stock_status',
            'salable_qty',
            'shipping_details', 
            'product_label',
            'mpn',
            this._getStockItemField(),
            this._getPriceRangeField() 
        ];

        // Additional fields, which we want to return always, except when it's variants on PLP (due to hugh number of items)
        if (!(isPlp && isVariant)) {
            fields.push(
                this._getProductImageField(),
                this._getProductThumbnailField(),
                this._getProductSmallField(),
                this._getShortDescriptionField(),
                'special_from_date',
                'special_to_date',
                this._getTierPricesField()
            );
        }

        // if it is normal product and we need attributes
        // or if, it is variant, but we need variant attributes or variants them-self
        if ((!isVariant && !noAttributes) || (isVariant && !noVariantAttributes && !noVariants)) {
            fields.push(this._getAttributesField(isVariant));
        }

        // to all products (non-variants)
        if (!isVariant) {
            fields.push(
                'url',
                this._getUrlRewritesFields(),
                this._getReviewCountField(),
                this._getRatingSummaryField(),
                this._getCustomizableProductFragment()
            );

            // if variants are not needed
            if (!noVariants) {
                fields.push(
                    this._getConfigurableProductFragment(),
                    this._getBundleProductFragment(),
                    this._getGroupedProductItems()
                );
            }
        }

        // prevent linked products from looping
        if (isForLinkedProducts) {
            fields.push(this._getProductLinksField());
        }

        // additional information to PDP loads
        if (isSingleProduct) {
            fields.push(
                'stock_status',
                this._getDescriptionField(),
                this._getProductLogosField(),
                this._getWarrantyRefundsField(),
                this._getMediaGalleryField(),
                this._getSimpleProductFragment(),
                'discontinue',
                'alternate_product_url',
                'bulk_product',
                'bulk_product_info',
                'display_old_specification',
                'display_specification',
                'stock_details'
            );

            // for variants of PDP requested product
            if (!isVariant) {
                fields.push(
                    'canonical_url',
                    'meta_title',
                    'meta_keyword',
                    'meta_description',
                    this._getCategoriesField(),
                    this._getReviewsField(),
                    this._getVirtualProductFragment(),
                    this._getCustomizableProductFragment(),
                    this._getProductLinksField()
                );
            }
        }

        return fields;
    }

   _getProductLogosFields() {
        return [
            'html'
        ];
    }

    _getProductLogosField() {
        return new Field('product_logos')
            .addFieldList(this._getProductLogosFields());
    }

     _getWarrantyRefundsFields() {
        return [
            'html'
        ];
    }

    _getWarrantyRefundsField() {
        return new Field('warranty_refunds')
            .addFieldList(this._getWarrantyRefundsFields());
    }

    _getCartProductInterfaceFields() {
        return [
            'uid',
            'id',
            'sku',
            'name',
            'type_id',
            'stock_status',
            'url',
            'salable_qty',
            this._getStockItemField(),
            this._getProductThumbnailField(),
            this._getCartConfigurableProductFragment(),
            this._getAttributesField(false, true),
            this._getProductLinksField(),
            this._getReviewCountField(),
            this._getRatingSummaryField(),
            this._getCustomizableProductFragment(),
            this._getPriceRangeField()
        ];
    }

};

export default new ProductListQuery();
