import { CheckoutDeliveryOptions as SourceCheckoutDeliveryOptions } from "SourceComponent/CheckoutDeliveryOptions/CheckoutDeliveryOptions.component";

import "./CheckoutDeliveryOptions.override.style";

import Field from "Component/PureForm/Field";
import FIELD_TYPE from "Component/PureForm/Field/Field.config";

import { formatPrice } from "Util/Price";

export class CheckoutDeliveryOptions extends SourceCheckoutDeliveryOptions {
  // TODO implement logic

  renderHeading() {
    const {
      estimateAddress: { postcode},
    } = this.props;

    return (
      <p block="Checkout" elem="Heading">
        {__(`Select freight for postcode ${postcode}`)}
      </p>
    );
  }

  renderNoDeliveryOptions() {
        return (
            <p block="CheckoutDeliveryOptions" elem="NoOptions">
                { __('Unfortunately, the address entered doesn’t seem quite right. Please try correct delivery address once again to proceed.') }
            </p>
        );
    }

  renderShippingMethods() {
    const { shippingMethods } = this.props;

    if (!shippingMethods.length) {
      return this.renderNoDeliveryOptions();
    }

    if (shippingMethods.length == 1) {
      const { method_code = "" } = shippingMethods[0];

      if (method_code == "pickup") {
        return this.renderNoDeliveryOptions();
      }
    }

    return shippingMethods.map(this.renderDeliveryOption);
  }

  handleSelectStoreInPickUp() {
    const { handleSelectDeliveryMethod, isShippingMethodPreSelected } =
      this.props;

    if (isShippingMethodPreSelected) {
      return;
    }

    // handleSelectDeliveryMethod();
  }

  render() {
    const {
      totals: { subtotal, tax_amount, subtotal_incl_tax, subtotal_with_discount},
      estimateAddress: { postcode, region_id, country_id, city },
      isCustomFeeActivated,
      customFeeAmount,
      customFeePriceType,
      customFeeName,
      currency,
      onOptionClick,
      selectedInsurance,
      isShowFee,
    } = this.props;

    var customFee = customFeeAmount;

    if (customFeePriceType == 1) {
      customFee = (subtotal_incl_tax * customFeeAmount) / 100;
    }

    if (!postcode || !region_id || !country_id || !city) {
      return null;
    }

    if (selectedInsurance == 0) {
      isShowFee(0);
    }

    return (
      <>
        <div block="CheckoutDeliveryOptions">
          {this.renderHeading()}
          <ul>{this.renderShippingMethods()}</ul>
          {this.renderSelectedShippingMethod()}
        </div>
        {isCustomFeeActivated ? (
          <div block="CheckoutDeliveryOptions">
            <p block="Checkout" elem="Heading">
              {__("Do you need insurance for your shipping?")}
            </p>
            <ul>
              <li block="CheckoutDeliveryOption">
                <button
                  block="CheckoutDeliveryOption"
                  elem="Button"
                  type="button"
                  onClick={(e) => {
                    // onOptionClick(e);
                    onOptionClick(1);
                  }}
                  style={{alignItems: "center"}}
                >
                  <Field
                    type={FIELD_TYPE.radio}
                    attr={{
                      id: "option-yes",
                      name: "option-yes",
                      value: 1,
                      checked: selectedInsurance == 1 ? true : false,
                    }}
                  />
                  <div block="CheckoutDeliveryOption" elem="Row" style={{alignItems: "center", display: "flex", justifyContent: "space-between"}}>
                    <span>
                      <span>Yes</span>
                      <br/>
                      <span style={{fontSize: "11px", color: "var(--paragraph-color)"}}>Full Cover For Items Lost Or Damaged In Transit.</span>
                    </span>
                    <strong> {formatPrice(customFee, currency)}</strong>
                  </div>
                </button>
              </li>
              <li block="CheckoutDeliveryOption">
                <button
                  block="CheckoutDeliveryOption"
                  elem="Button"
                  type="button"
                  onClick={(e) => {
                    // onOptionClick(e);
                    onOptionClick(0);
                  }}
                  style={{alignItems: "center"}}
                >
                  <Field
                    type={FIELD_TYPE.radio}
                    attr={{
                      id: "option-no",
                      name: "option-no",
                      value: 0,
                      checked: selectedInsurance == 0 ? true : false,
                    }}
                  />
                  <div block="CheckoutDeliveryOption" elem="Row" style={{alignItems: "center", display: "flex", justifyContent: "space-between"}}>
                    <span>
                      <span value={0}>No</span>
                      <br/>
                      <span style={{fontSize: "11px", color: "var(--paragraph-color)"}}>I Don't Want Freight Insurance For My Items.</span>
                    </span>
                    <strong>{formatPrice(0.0, currency)}</strong>
                  </div>
                </button>
              </li>
            </ul>
          </div>
        ) : (
          ""
        )}
      </>
    );
  }
}

export default CheckoutDeliveryOptions;
