import { CheckoutDeliveryOption as SourceCheckoutDeliveryOption } from "SourceComponent/CheckoutDeliveryOption/CheckoutDeliveryOption.component";

import "./CheckoutDeliveryOption.override.style";

import Field from "Component/PureForm/Field";
import FIELD_TYPE from "Component/PureForm/Field/Field.config";
let selectedNew; 


export class CheckoutDeliveryOption extends SourceCheckoutDeliveryOption {
  // TODO implement logic

  renderPrice() {
    const { option: { available } = {} } = this.props;

    if (!available) {
      return null;
    }

    var priceText = `${this.getOptionPrice()}`;

    if (this.getOptionPrice() == "$0.00") {
      priceText = __("Free");
    }

    return <strong>{priceText}</strong>;
  }

  renderRow() {
    const { option: { carrier_code, carrier_title, method_code, error_message } = {} } =
      this.props;

    var method_name = "";

    if (method_code && carrier_code == "shippit") {
      method_name = method_code + " " + "Delivery.";
    }
    if (method_code && carrier_code == "freeshipping") {
      method_name = "Standard" + " " + "Delivery";
    }

    return (
     <>
        <div block="CheckoutDeliveryOption" 
        elem="Row" 
        style={{display: "flex",
        justifyContent: "space-between",
        alignItems: "center",}}>
          <span>
          <span>
           {method_name}
          </span>
          <br/>
          {method_name == "Standard Delivery" && <span style={{fontSize: "11px", color: "var(--paragraph-color)"}}>{"Standard 5 to 8 Business Days once Dispatched."}</span>}
          {method_name == "Standard Delivery." && <span style={{fontSize: "11px", color: "var(--paragraph-color)"}}>{error_message || "Standard 3 to 8 Business Days once Dispatched."}</span>}
          {method_name == "Express Delivery." && <span style={{fontSize: "11px", color: "var(--paragraph-color)"}}>{error_message || "Express 2 to 5 Business Days once Dispatched."}</span>}
          </span>
          {this.renderPrice()}
          {this.renderAvailabilityMessage()}
        </div>
      </>
    );
  }
  
  componentDidMount() {
    const {
      option: { carrier_title, available, method_code } = {},
      onOptionClick,
      isSelected,
      shippingInitialMethod
    } = this.props;

    if (method_code == "pickup") {
      return null;
    }

    let shippingNewMethod;

    console.log("Initial",shippingInitialMethod)
    if(shippingInitialMethod !== null && shippingInitialMethod !== undefined) {
      shippingNewMethod = shippingInitialMethod.split("_")[1];
      // if(shippingNewMethod === method_code) {
      //   selectedNew = true
      //   onOptionClick()
      // } else {
      //   selectedNew = false
      // }
    }

  }

  render() {
    const {
      option: { carrier_title, available, method_code } = {},
      onOptionClick,
      isSelected,
      shippingInitialMethod
    } = this.props;

    if (method_code == "pickup") {
      return null;
    }

    // let shippingNewMethod;
    // let selectedNew; 

    // console.log("Initial",shippingInitialMethod)
    // if(shippingInitialMethod !== null && shippingInitialMethod !== undefined) {
    //   shippingNewMethod = shippingInitialMethod.split("_")[1];
    //   if(shippingNewMethod === method_code) {
    //     selectedNew = true
    //     onOptionClick()
    //   } else {
    //     selectedNew = false
    //   }
    // }

    return (
      <li block="CheckoutDeliveryOption" mods={{ isDisabled: !available }}>
        <button
          block="CheckoutDeliveryOption"
          mods={{ isDisabled: !available }}
          elem="Button"
          type="button"
          style={{
            cursor: "alias",
            justifyContent: "space-between",
            alignItems: "center",
          }}
          onClick={onOptionClick}
          disabled={!available}
        >
          <Field
            type={FIELD_TYPE.radio}
            attr={{
              id: `option-${carrier_title == "Free Shipping" ? "Shippit" : carrier_title}`,
              name: `option-${carrier_title == "Free Shipping" ? "Shippit" : carrier_title}`,
              checked: selectedNew,
              // checked: selectedNew || !!isSelected,
              // checked: 
            }}
          />
          {this.renderRow()}
        </button>
      </li>
    );
  }
}

export default CheckoutDeliveryOption;
