
export class CheckoutGuestFormStatePlugin {
    mapStateToProps = (args, callback, instance) => {
        const [state] = args;

        return {
            ...callback.apply(instance, args),
            logins: state.SocialLoginReducer.logins,
            isSocialLoginsLoading: state.SocialLoginReducer.isLoading
        };
    };

    containerProps = (args, callback, instance) => {
        const { logins, isSocialLoginsLoading } = instance.props;
        return {
            ...callback.apply(instance, args),
            logins,
            isSocialLoginsLoading
        };
    };
}

export const { mapStateToProps, containerProps } = new CheckoutGuestFormStatePlugin();

export const config = {
    'Component/CheckoutGuestForm/Container/mapStateToProps': {
        function: [
            {
                position: 100,
                implementation: mapStateToProps
            }
        ]
    },
    'Component/CheckoutGuestForm/Container': {
        'member-function': {
            containerProps: [
                {
                    position: 110,
                    implementation: containerProps
                }
            ]
        }
    }
};

export default config;
